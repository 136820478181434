import { Flex } from '@chakra-ui/react';
import { createContext, useContext, useState } from 'react';
import { AppLoader } from '../../components';

const LoadingContext = createContext({
  loading: false,
  setLoading: (show: boolean) => {},
});

export function LoadingProvider({ children }: any) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  return (
    <LoadingContext.Provider value={value}>
      {children}

      {loading && <AppLoader />}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider');
  }
  return context;
}
