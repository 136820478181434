export interface DateRangeRequest {
  from: string;
  to: string;
}

export interface FacilityBookingResult {
  facility: string;
  bookingCount: number;
  bookingHourCount: number;
}

export interface OrganizationFacilityResult {
  organization: string;
  facilityResults: OrganizationFacilityBookingResult[];
}

export interface OrganizationFacilityBookingResult {
  facility: string;
  bookingCount: number;
  bookingHourCount: number;
}

export const DateFormDefaultValues: DateForm = {
  from: new Date(),
  to: undefined,
};

export type DateForm = {
  from: Date;
  to?: Date;
};
