import React from 'react';
import { Card, CardProps, Text, useColorModeValue } from '@chakra-ui/react';

export type AppCardProps = {
  title?: string;
} & CardProps;

export const AppCard: React.FC<AppCardProps> = ({
  title,
  bgColor = 'white',
  children,
  ...props
}) => {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  return (
    <Card
      {...props}
      variant="filled"
      bg={bgColor}
      p="4"
      rounded={'2xl'}
      mb={'20px'}
    >
      {title && (
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          {title}
        </Text>
      )}

      {children}
    </Card>
  );
};
