import {
  Checkbox,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form';

export type AppCheckboxProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  checkboxLabel?: string;
  label?: string;
};

export const AppCheckbox = <T extends FieldValues>({
  control,
  name,
  error,
  label = '',
  checkboxLabel = '',
  ...props
}: AppCheckboxProps<T>) => {
  // useEffect(() => {
  //   console.log(error);
  // }, [error]);

  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <Checkbox
              defaultChecked={value}
              onChange={(e) => onChange(e.currentTarget.checked)}
            >
              {checkboxLabel}
            </Checkbox>
            {error && <div style={{ color: 'red' }}>{error?.message}</div>}
          </FormControl>
        )}
      />
    </Flex>
  );
};
