import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesList } from './router';
import {
  isAuthenticated,
  selectUser,
} from '../store/slices/authentication.slice';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../store/store';
import { UserRole } from '@giflo/shared';

interface ProtectedRouteProps {
  route?: RoutesList;
  children: React.ReactNode | React.ReactNode[] | undefined;
}

const ProtectedRoute = ({ route, children }: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const userIsAuthenticated = useSelector(isAuthenticated);
  const user = useAppSelector(selectUser);

  const checkUserToken = () => {
    if (!userIsAuthenticated) {
      setIsLoggedIn(false);
      return navigate(RoutesList.Index);
    }

    if (user?.roleId === UserRole.ADMIN) {
      setIsLoggedIn(true);
      routeConditionsGaurd();
    } else {
      setIsLoggedIn(false);
      return navigate(RoutesList.Index);
    }
  };

  const routeConditionsGaurd = () => {
    if (route) {
      switch (route) {
        case RoutesList.Dashboard:
          return navigate(RoutesList.Dashboard);
        default:
          break;
      }
    }
  };

  useEffect(() => {
    checkUserToken();
    //routeConditionsGaurd();
  }, [isLoggedIn]);

  return <React.Fragment>{isLoggedIn ? children : null}</React.Fragment>;
};
export default ProtectedRoute;
