import { createApi } from '@reduxjs/toolkit/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  Facility,
  FacilityForm,
  ByOptionsRequest,
  PaginatedData,
} from '@giflo/shared';

export const facilityApi = createApi({
  reducerPath: 'facilityApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchFacilitiesByOptions: build.query<
      PaginatedData<Facility>,
      ByOptionsRequest
    >({
      query: (data) => ({
        url: `/facility/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<Facility>) => {
        return response;
      },
    }),
    fetchAllFacilities: build.query<Facility[], any>({
      query: () => ({
        url: '/facility',
        method: 'GET',
      }),
      transformResponse: (response: Facility[]) => {
        return response;
      },
    }),
    fetchFacilityById: build.query<Facility, string>({
      query: (id) => ({
        url: `/facility/${id}`,
        method: 'GET',
      }),
    }),
    createFacility: build.mutation<Facility, FacilityForm>({
      query: (body) => ({
        url: '/facility/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: Facility) => {
        return response;
      },
    }),
    editFacility: build.mutation<Facility, FacilityForm>({
      query: (body) => ({
        url: `/facility/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Facility) => {
        return response;
      },
    }),
    deleteFacility: build.mutation<string, string>({
      query: (id) => ({
        url: `/facility/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchFacilitiesByOptionsQuery,
  useFetchAllFacilitiesQuery,
  useLazyFetchAllFacilitiesQuery,
  useFetchFacilityByIdQuery,
  useCreateFacilityMutation,
  useEditFacilityMutation,
  useDeleteFacilityMutation,
} = facilityApi;
