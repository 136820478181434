import BaseLayout from '../../components/layouts/base-layout';
import {
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tab,
} from '@chakra-ui/react';
import { useDataTable, SelectOption, Booking, AppCard } from '@giflo/shared';
import React, { useEffect, useState } from 'react';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizations';
import { useFetchAllFacilitiesQuery } from '../../store/api/facilityApi';
import { useFetchOrganizationBookingsByOptionsQuery } from '../../store/api/organizationBookingApi';
import { useFetchAllusersQuery } from '../../store/api/userApi';
import ReportAllBookings from './sub-pages/report-all-bookings';
import ReportAllFacilityBookings from './sub-pages/report-all-facility-bookings';
import ReportAllOrganizationFacilityBookings from './sub-pages/report-all-organization-facility-bookings';

const Reports: React.FC = () => {
  const { data: organizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });

  const { data: facilities } = useFetchAllFacilitiesQuery({
    refetchOnMountArgChange: true,
  });

  const { data: users } = useFetchAllusersQuery({
    refetchOnMountArgChange: true,
  });

  const [organizationOptions, setOrganizationOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>('');

  const [facilityOptions, setFacilityOptions] = useState<SelectOption[]>([]);
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>('');

  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  useEffect(() => {
    if (organizations) {
      const options: SelectOption[] = organizations.map((x) => {
        return {
          label: x.isActive === false ? `${x.name} (InActive)` : x.name,
          value: x.id,
        };
      });

      setOrganizationOptions(options);
    }
  }, [organizations]);

  useEffect(() => {
    if (facilities) {
      const options: SelectOption[] = facilities.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });

      setFacilityOptions(options);
    }
  }, [facilities]);

  useEffect(() => {
    if (users) {
      const options: SelectOption[] = users.map((x) => {
        return {
          label: `${x.firstName} ${x.lastName}`,
          value: x.id,
        };
      });

      setUserOptions(options);
    }
  }, [users]);

  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
    handleSearchChange,
  } = useDataTable<Booking>();

  const { data: bookingResponse, isFetching } =
    useFetchOrganizationBookingsByOptionsQuery(
      {
        facilityId: selectedFacilityId,
        organizationId: selectedOrganizationId,
        userId: selectedUserId,
        pageNumber: nextPaginationQueryParams.page,
        pageSize: nextPaginationQueryParams.pageSize,
        sortParams: nextPaginationQueryParams.sortList,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (bookingResponse?.pagination) {
      setPagination(bookingResponse?.pagination);
    }
  }, [bookingResponse]);

  return (
    <BaseLayout>
      <Flex direction={'column'} w={'full'}>
        <AppCard m={4}>
          <Tabs isFitted variant='enclosed' isLazy>
            <TabList>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: '#222222',
                }}
              >
                All bookings
              </Tab>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: '#222222',
                }}
              >
                Facility bookings
              </Tab>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: '#222222',
                }}
              >
                Organization Facility Bookings
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel py={4} w='100%'>
                <ReportAllBookings />
              </TabPanel>
              <TabPanel py={4} w='100%'>
                <ReportAllFacilityBookings />
              </TabPanel>
              <TabPanel py={4} w='100%'>
                <ReportAllOrganizationFacilityBookings />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </AppCard>
      </Flex>
    </BaseLayout>
  );
};
export default Reports;
