import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { authenticationSlice } from './slices/authentication.slice';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { userApi } from './api/userApi';
import { facilityApi } from './api/facilityApi';
import { organizationsApi } from './api/organizations';
import { authenticationApi } from './api/authenticationApi';
import { contactsApi } from './api/contactsApi';
import { activityApi } from './api/activityApi';
import { organizationBookingApi } from './api/organizationBookingApi';
import { facilitySlice } from './slices/facilities.slice';
import { assetApi } from './api/assetApi';
import { bookingsSlice } from './slices/booking.slice';
import { reportApi } from './api/reportApi';

const authPersistedReducer = persistReducer(
  {
    key: 'auth',
    storage,
  },
  authenticationSlice.reducer
);

const bookingsPersistedReducer = persistReducer(
  {
    key: 'bookings',
    storage,
  },
  bookingsSlice.reducer
);

const facilityPersistedReducer = persistReducer(
  {
    key: 'facility',
    storage,
  },
  facilitySlice.reducer
);

export const store = configureStore({
  reducer: {
    authentication: authPersistedReducer,
    facility: facilityPersistedReducer,
    bookings: bookingsPersistedReducer,
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [facilityApi.reducerPath]: facilityApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [activityApi.reducerPath]: activityApi.reducer,
    [organizationBookingApi.reducerPath]: organizationBookingApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authenticationApi.middleware,
      userApi.middleware,
      facilityApi.middleware,
      organizationsApi.middleware,
      contactsApi.middleware,
      organizationBookingApi.middleware,
      assetApi.middleware,
      activityApi.middleware,
      organizationBookingApi.middleware,
      reportApi.middleware
    ),
});

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
