import { createApi } from '@reduxjs/toolkit/query/react';
import { LoginParams } from '../../models/login';
import { UserAuth } from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    userAuthentication: build.mutation<UserAuth, LoginParams>({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUserAuthenticationMutation } = authenticationApi;
