import React from 'react';
import { Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ActivityForm,
  AppButton,
  AppColors,
  AppDatePicker,
  AppInput,
  AppMarkdown,
} from '@giflo/shared';
import { BaseFormProps } from './base-forms';

const activityFormDefaultValues: ActivityForm = {
  title: '',
  description: '',
  dateExpired: undefined,
  isSent: false,
};

const activityFormDataSchema = yup.object({
  title: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
});

type ActivityFormProps<T> = {
  form?: ActivityForm;
} & BaseFormProps<T>;

const ActivitiesForm: React.FC<ActivityFormProps<ActivityForm>> = ({
  isLoading,
  form,
  onSubmit,
}) => {
  const {
    control: control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ActivityForm>({
    defaultValues: form || activityFormDefaultValues,
    resolver: yupResolver(activityFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Stack spacing={4}>
      <AppInput<ActivityForm>
        name="title"
        control={control}
        error={errors.title}
        label="Title"
      />
      <AppMarkdown<ActivityForm>
        name="description"
        control={control}
        error={errors.description}
        label="Description"
      />
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        onClick={handleSubmit(onSubmit)}
        isDisabled={!isValid}
        mt={12}
      >
        Save
      </AppButton>
    </Stack>
  );
};

export default ActivitiesForm;
