import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDefinitionType,
  useLoading,
  AppDatePicker,
  AppText,
  AppButton,
  AppColors,
  useDialog,
} from '@giflo/shared';
import { AppDataTable } from '@giflo/shared';
import React, { useState } from 'react';
import { useLazyFetchReportOrganizationFacilityBookingsQuery } from '../../../store/api/reportApi';
import {
  DateForm,
  DateFormDefaultValues,
  OrganizationFacilityBookingResult,
  OrganizationFacilityResult,
} from '../../../models/report';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';
import { useFetchAllFacilitiesQuery } from '../../../store/api/facilityApi';
const { JSONtoCSVConverter } = require('react-json-csv-convert');

const ReportAllOrganizationFacilityBookings: React.FC = () => {
  const { setLoading } = useLoading();
  const [getReportData] = useLazyFetchReportOrganizationFacilityBookingsQuery();
  const [reportData, setReportData] = useState<OrganizationFacilityResult[]>(
    []
  );
  const dialog = useDialog();

  const { data: facilities } = useFetchAllFacilitiesQuery({
    refetchOnMountArgChange: true,
  });

  const {
    control: deliveryDateControl,
    formState: { errors: deliveryDateErrors },
    watch: deliveryDateWatch,
  } = useForm<DateForm>({
    defaultValues: DateFormDefaultValues,
    mode: 'onChange',
  });

  deliveryDateWatch((data, { name }) => {
    if (data.from && data.to) {
      setupReportData(data.from, data.to);
    }
  });

  const expandedTableColumns: ColumnDefinitionType<OrganizationFacilityBookingResult>[] =
    [
      {
        key: 'facility',
        header: 'Facility',
      },
      {
        key: 'bookingCount',
        header: 'Booking Count',
      },
      {
        key: 'bookingHourCount',
        header: 'Booked Hours',
      },
    ];

  const setupReportData = async (from: Date, to: Date) => {
    try {
      setLoading(true);

      const response = await getReportData({
        from: format(new Date(from), 'yyyyMMdd'),
        to: format(new Date(to), 'yyyyMMdd'),
      }).unwrap();
      // setReportData(response);

      setReportData(
        response.map((reportData) => {
          const facilityResultCount = reportData.facilityResults?.length ?? 0;
          return {
            ...reportData,
            expandText: `${facilityResultCount} Facilty Items`,
            expandRender: (
              <AppDataTable
                showColumnVisibility={false}
                showExpanding={false}
                searchBar={false}
                selectableRows={false}
                data={reportData.facilityResults || []}
                columns={expandedTableColumns}
              />
            ),
          };
        })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const setupExportLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleExportCSVBookingClicked = () => {
    const dataToExport = reportData.map((x) => {
      return {
        organization: x.organization,
        sensoriumMeetingRoom5: x.facilityResults[0].bookingCount,
        kwvRaadsaal: x.facilityResults[1].bookingCount,
        barberClub: x.facilityResults[2].bookingCount,
        sensoriumMeetingRoom6: x.facilityResults[3].bookingCount,
        sensoriumMeetingRoom4: x.facilityResults[4].bookingCount,
        dieKlubhuis: x.facilityResults[5].bookingCount,
        sensoriumMeetingRoom1: x.facilityResults[6].bookingCount,
        sensoriumMeetingRoom2: x.facilityResults[7].bookingCount,
        sensoriumMeetingRoom3: x.facilityResults[8].bookingCount,
      };
    });

    const headers = [
      'ORGANIZATION',
      'SENSORIUM MEETING ROOM 5',
      'KWV RAADSAAL',
      'BARBER CLUB',
      'SENSORIUM MEETING ROOM 6',
      'SENSORIUM MEETING ROOM 4',
      'DIE KLUBHUIS',
      'SENSORIUM MEETING ROOM 1',
      'SENSORIUM MEETING ROOM 2',
      'SENSORIUM MEETING ROOM 3',
    ];
    const csvConfig = {
      headers,
      keys: [
        'organization',
        'sensoriumMeetingRoom5',
        'kwvRaadsaal',
        'barberClub',
        'sensoriumMeetingRoom6',
        'sensoriumMeetingRoom4',
        'dieKlubhuis',
        'sensoriumMeetingRoom1',
        'sensoriumMeetingRoom2',
        'sensoriumMeetingRoom3',
      ],
      actions: Object.keys(headers).map((x) => null),
    };

    dialog({
      title: 'Download Excel',
      render: (onSubmit, onCancel) => {
        return (
          <JSONtoCSVConverter
            csvConfig={csvConfig}
            data={dataToExport}
            styleProp={{ display: 'inline-block' }}
            fileName={`facility-booking-report-export-${format(
              new Date(),
              'dd MMM yyyy'
            )}`}
          >
            <AppButton
              bgColor={AppColors.primary}
              mr="4"
              color={'white'}
              onClick={() => {
                setupExportLoader();
                onCancel();
              }}
            >
              Yes
            </AppButton>
          </JSONtoCSVConverter>
        );
      },
    });
  };

  return (
    <>
      <Flex w={'100%'} justify={'space-between'} align={'center'}>
        <form>
          <Flex gap={8}>
            <AppDatePicker<DateForm>
              control={deliveryDateControl}
              name="from"
              label="From"
              error={deliveryDateErrors.from}
            />

            <AppDatePicker<DateForm>
              control={deliveryDateControl}
              name="to"
              label="To"
              error={deliveryDateErrors.to}
            />
          </Flex>
        </form>
        <AppButton
          bgColor={AppColors.tertiary}
          color={'white'}
          onClick={handleExportCSVBookingClicked}
          size="sm"
          borderRadius={0}
          mr="4"
          isDisabled={reportData?.length! > 0 ? false : true}
        >
          Export To Excel
        </AppButton>
      </Flex>

      <Flex
        rounded={'2xl'}
        overflow={'auto'}
        padding={4}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        {/* <AppDataTable
          data={reportData || []}
          showColumnVisibility={false}
          noDataMessage="No data"
          searchBar={false}
          columns={bookingColumns || []}
          selectableRows={false}
          showExpanding={true}
        /> */}

        <Box mt={4} h={'55vh'}>
          <TableContainer zIndex={1}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Organization</Th>

                  {facilities &&
                    facilities.length > 0 &&
                    facilities?.map((facility) => (
                      <Th key={facility.id}>{facility.name}</Th>
                    ))}
                </Tr>
              </Thead>
              <Tbody>
                {reportData.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td>
                        <AppText>{item.organization}</AppText>
                      </Td>

                      {facilities &&
                        facilities.length > 0 &&
                        facilities?.map((facility) => {
                          const facilityResult = item.facilityResults.find(
                            (x) => x.facility === facility.name
                          );
                          return (
                            <Td key={facility.id}>
                              <AppText>{facilityResult?.bookingCount}</AppText>
                            </Td>
                          );
                        })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </>
  );
};
export default ReportAllOrganizationFacilityBookings;
