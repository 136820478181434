import { AppFetchBaseQuery } from './apiBase';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

import {
  PaginatedData,
  ByOptionsRequest,
  Activity,
  ActivityForm,
  ActivityEditForm,
} from '@giflo/shared';

export const activityApi = createApi({
  reducerPath: 'activityApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchActivitiesByOptions: build.query<
      PaginatedData<Activity>,
      ByOptionsRequest
    >({
      query: (data) => ({
        url: `/activity/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<Activity>) => {
        return response;
      },
    }),
    fetchAllActivities: build.query<Activity[], any>({
      query: () => ({
        url: '/activity',
        method: 'GET',
      }),
    }),
    fetchActivityById: build.query<Activity, string>({
      query: (id) => ({
        url: `/activity/${id}`,
        method: 'GET',
      }),
    }),
    createActivity: build.mutation<Activity, ActivityForm>({
      query: (body) => ({
        url: `/activity/create`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: Activity) => {
        return response;
      },
    }),
    updateActivity: build.mutation<Activity, ActivityEditForm>({
      query: (body) => ({
        url: `/activity/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Activity) => {
        return response;
      },
    }),
    sendActivity: build.mutation<Activity, Activity>({
      query: (body) => ({
        url: `/activity/send/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Activity) => {
        return response;
      },
    }),
    deleteActivity: build.mutation<string, string>({
      query: (id) => ({
        url: `/activity/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchActivitiesByOptionsQuery,
  useFetchAllActivitiesQuery,
  useFetchActivityByIdQuery,
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useSendActivityMutation,
  useDeleteActivityMutation,
} = activityApi;
