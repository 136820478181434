import {
  Flex,
  Box,
  Stack,
  Image,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HttpError } from '../../models/httpError';
import { RoutesList } from '../../router/router';
import { useUserAuthenticationMutation } from '../../store/api/authenticationApi';
import { setUserAuth, logout } from '../../store/slices/authentication.slice';
import Logo from '../../assets/Giflo-logo.png';
import logo from '../../assets/Logo White and Blue.png';
import building from '../../assets/building-login.jpeg';
import LoginForm, { LoginFormData } from '../../components/forms/login-form';
import { AppColors, AppText } from '@giflo/shared';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [userAuthentication, { data, isLoading, error, isSuccess, isError }] =
    useUserAuthenticationMutation();

  const dispatch = useDispatch();

  const signIn = async (loginFormData: LoginFormData) => {
    try {
      await dispatch(logout());

      const res = await userAuthentication({
        username: loginFormData.username,
        password: loginFormData.password,
      }).unwrap();

      dispatch(setUserAuth(res));
      navigate(RoutesList.Dashboard);
    } catch (errResponse) {
      console.error(errResponse);
      const httpError: HttpError = (errResponse as any).data as HttpError;

      toast({
        title: 'Login Failed',
        description: httpError?.error?.message || 'Login failed.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    // <Flex
    //   h={'100vh'}
    //   align={'center'}
    //   justify={'center'}
    //   m={'33px'}
    //   border={`1px solid ${AppColors.appBorder}`}
    //   borderRadius={'15px'}
    // >
    //   <Stack
    //     spacing={8}
    //     mx={'auto'}
    //     maxW={'lg'}
    //     py={12}
    //     px={6}
    //     boxShadow={'lg'}
    //     borderRadius={'15px'}
    //     bg={AppColors.secondary}
    //   >
    //     <Stack align={'center'}>
    //       <Image src={Logo} alt="" objectFit={'cover'} />
    //     </Stack>
    //     <Box
    //       w={{ md: '380px' }}
    //       bg={AppColors.secondary}
    //       rounded={'md'}
    //       overflow={'hidden'}
    //       padding={'4'}
    //       p={8}
    //     >
    //       <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
    //     </Box>
    //   </Stack>
    // </Flex>
    <Flex
      minH={'100vh'}
      minW={'100vw'}
      align={'center'}
      justify={'center'}
      direction={{ base: 'column', lg: 'row' }}
    >
      <Flex>
        <Flex
          direction={'column'}
          align={'center'}
          justify={'center'}
          bgColor={AppColors.primary}
          h={'100vh'}
          w={{ base: '100vw', lg: '50vw' }}
        >
          <Stack align={'center'} p={8}>
            <Image h={'28'} w={'auto'} src={logo} alt="" objectFit={'cover'} />
          </Stack>
          <Stack
            bgColor={'white'}
            spacing={8}
            mx={'auto'}
            maxW={'lg'}
            p={8}
            py={14}
            boxShadow={'xl'}
            borderRadius={'15px'}
            mt={2}
          >
            <AppText
              variant={'body'}
              textAlign={'center'}
              fontSize={'xl'}
              fontWeight={'600'}
            >
              Booking - Admin Portal
            </AppText>
            <Box
              w={{ md: '380px' }}
              bg={useColorModeValue('white', 'gray.800')}
              rounded={'md'}
              overflow={'hidden'}
              px={8}
            >
              <LoginForm isLoading={isLoading} onSubmit={signIn}></LoginForm>
            </Box>
          </Stack>
        </Flex>
      </Flex>
      <Box
        bgImg={building}
        bgSize={'cover'}
        bgPos={'center'}
        filter={'auto'}
        brightness={'60%'}
        w={{ base: '100vw', lg: '50vw' }}
        h={{ base: 'auto', lg: '100vh' }}
      ></Box>
    </Flex>
  );
};

export default Login;
