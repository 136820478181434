import { AppFetchBaseQuery } from './apiBase';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AssetCreateRequest, AssetCreateResponse } from '../../models/asset';

export const assetApi = createApi({
  reducerPath: 'assetApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fileUpload: build.mutation<AssetCreateResponse, AssetCreateRequest>({
      query: (body) => ({
        url: `/asset/upload`,
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: AssetCreateResponse) => {
        return response;
      },
    }),
  }),
});

export const { useFileUploadMutation } = assetApi;
