import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { RootState } from '../store';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const AppFetchBaseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}/`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authentication.token;
    if (token) {
      // include token in req header
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    }
  },
});
