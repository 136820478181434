import React, { useState } from 'react';
import {
  Box,
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  useToast,
} from '@chakra-ui/react';
import BookingsForm from './booking-form';
import BookingConfirmation from './booking-confirmation';
import { AppLoader, BookingForm, Time, useLoading } from '@giflo/shared';
import { useSelector } from 'react-redux';
import {
  selectBookingDay,
  selectedBookingOrganization,
  selectedEndTime,
  selectedFacility,
  selectedStartTime,
} from '../../store/slices/booking.slice';
import { useCreateOrganizationBookingMutation } from '../../store/api/organizationBookingApi';
import { format } from 'date-fns';

export type BookingFlowFormProps = {
  handleSubmit: () => void;
};

export const BookingFlowForm: React.FC<BookingFlowFormProps> = ({
  handleSubmit,
}) => {
  const organizationId = useSelector(selectedBookingOrganization);
  const { setLoading, loading } = useLoading();
  const [saveBookingTrigger] = useCreateOrganizationBookingMutation();
  const toast = useToast();
  const facility = useSelector(selectedFacility);
  const bookingStart = useSelector(selectedStartTime);
  const bookingEnd = useSelector(selectedEndTime);
  const bookingDate = useSelector(selectBookingDay);

  const handleConfirmBooking = async () => {
    try {
      setLoading(true);
      let endTime: Time = {
        hour: bookingEnd?.hour ?? 10,
        minute: bookingEnd?.minute ?? 0,
      };

      const date = new Date(bookingDate!);

      const newBooking: BookingForm = {
        organizationId: organizationId ?? '',
        facilityId: facility?.id ?? '',
        day: date,
        start: bookingStart,
        end: endTime,
      };

      await upsertBooking(newBooking);
      setLoading(false);
    } catch (e) {
      toast({
        title: 'Booking save issue',
        description:
          "We've run into an issue saving the booking you created, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const upsertBooking = async (formData: BookingForm) => {
    try {
      const bookingId = await saveBookingTrigger({
        organizationId: formData.organizationId,
        facilityId: formData.facilityId,
        day: format(new Date(formData.day!), 'yyyyMMdd'),
        start: formData.start,
        end: formData.end,
      }).unwrap();

      if (bookingId) {
        handleSubmit();
        toast({
          title: 'Booking saved!',
          status: 'success',
          description: `We've saved the Booking you created`,
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Booking slot not available',
          description:
            'It seems someone booked your slot before you could confirm, please go back & select another available slot.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (e) {
      toast({
        title: 'Booking save issue',
        description:
          "We've run into an issue saving the booking you created, contact support for help",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const steps = [
    {
      title: 'Book Facility',
    },
    {
      title: 'Confirm Booking',
    },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: steps.length,
  });
  const [step, setStep] = useState<number>(1);

  const booking: BookingForm = {
    organizationId: organizationId ?? '',
    facilityId: facility?.id!,
    start: bookingStart,
    end: bookingEnd,
    day: new Date(bookingDate!),
  };

  return (
    <Flex flex={1} direction="column" gap={12}>
      {loading && <AppLoader />}
      <Flex w={'full'} justify={'space-evenly'}>
        <Stepper index={activeStep} w={'full'}>
          {steps.map((step, index) => (
            <Step key={index} onClick={() => setActiveStep(index)}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Flex>
      <Flex w={'full'} direction={'column'}>
        {step === 1 ? (
          <BookingsForm
            onSubmit={async (formData) => {
              setStep(2);
            }}
          />
        ) : (
          <BookingConfirmation
            booking={booking}
            handleSubmit={async () => {
              await handleConfirmBooking();
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default BookingFlowForm;
