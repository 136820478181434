import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import Router from './router/router';
import {
  DialogServiceProvider,
  LoadingProvider,
  PanelServiceProvider,
  theme,
} from '@giflo/shared';

export const App = () => (
  <ChakraProvider theme={theme}>
    <LoadingProvider>
      <PanelServiceProvider>
        <DialogServiceProvider>
          <Router></Router>
        </DialogServiceProvider>
      </PanelServiceProvider>
    </LoadingProvider>
  </ChakraProvider>
);
