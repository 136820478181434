import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppButton,
  AppColors,
  AppDatePicker,
  AppInput,
  SelectListFilter,
  SelectOption,
  OrganizationFormData,
  OrganizationType,
} from '@giflo/shared';
import { BaseFormProps } from './base-forms';

const organizationFormDefaultValues: OrganizationFormData = {
  name: '',
  contractExpiryDate: new Date(),
  companyVAT: '',
  companyReg: '',
  website: '',
  isActive: true,
  buildingUnit: '',
  type: OrganizationType.INTERNAL,
};

const organizationFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  contractExpiryDate: yup.date().required('Field is required'),
});

type OrganizationFormProps<T> = {
  form?: OrganizationFormData;
} & BaseFormProps<T>;

const OrganizationForm: React.FC<
  OrganizationFormProps<OrganizationFormData>
> = ({ isLoading, form, onSubmit }) => {
  const [organizationTypeSelectors, setOrganizationTypeSelectors] =
    useState<SelectOption[]>();

  const {
    control: control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<OrganizationFormData>({
    defaultValues: form || organizationFormDefaultValues,
    resolver: yupResolver(organizationFormDataSchema),
    mode: 'onChange',
  });

  // const organizationTypes = Object.values(OrganizationType).filter((x) => {
  //   return isNaN(Number(x));
  // });

  useEffect(() => {
    const selectors: SelectOption[] = [
      { label: 'Internal', value: OrganizationType.INTERNAL },
      { label: 'External', value: OrganizationType.EXTERNAL },
    ];
    setOrganizationTypeSelectors(selectors);
  }, []);

  const handleRoleOptionSelect = (item: SelectOption) => {
    const selectedType = item.value!;
    if (selectedType === 'EXTERNAL') {
      setValue('type', OrganizationType.EXTERNAL);
    } else {
      setValue('type', OrganizationType.INTERNAL);
    }
  };

  const getOrgTypeDefaultOption = () => {
    if (form && form.type !== undefined) {
      const defaultValue = organizationTypeSelectors?.find(
        (x) => x.value === form.type
      );

      return defaultValue;
    }

    return undefined;
  };

  return (
    <Stack spacing={4}>
      <SelectListFilter
        mt={4}
        options={organizationTypeSelectors || []}
        defaultOption={getOrgTypeDefaultOption()}
        isInModal
        isMulti={false}
        placeholder="Select Orgnization Type"
        onSelectionChange={(value: SelectOption[]) => {
          handleRoleOptionSelect(value[0]);
        }}
      />
      <AppInput<OrganizationFormData>
        name="name"
        error={errors.name}
        control={control}
        label="Organization Name"
      />
      <AppInput<OrganizationFormData>
        name="companyReg"
        error={errors.companyReg}
        control={control}
        label="Organization Registration"
      />
      <AppInput<OrganizationFormData>
        name="companyVAT"
        error={errors.companyVAT}
        control={control}
        label="Organization VAT Number"
      />
      <AppInput<OrganizationFormData>
        name="website"
        error={errors.website}
        control={control}
        label="Organization Website"
      />
      <AppInput<OrganizationFormData>
        name="buildingUnit"
        error={errors.buildingUnit}
        control={control}
        label="Building Unit"
      />
      <AppDatePicker<OrganizationFormData>
        name="contractExpiryDate"
        control={control}
        error={undefined}
        label="Select Contract Expiry Date"
      />
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        isDisabled={!isValid}
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </AppButton>
    </Stack>
  );
};
export default OrganizationForm;
