import { Flex, useColorModeValue } from '@chakra-ui/react';
import {
  ColumnDefinitionType,
  useLoading,
  FacilityBookingResult,
  AppDatePicker,
} from '@giflo/shared';
import { AppDataTable } from '@giflo/shared';
import React, { useState } from 'react';
import { useLazyFetchReportFacilityBookingsQuery } from '../../../store/api/reportApi';
import { DateForm, DateFormDefaultValues } from '../../../models/report';
import { useForm } from 'react-hook-form';
import { format } from 'date-fns';

const ReportAllFacilityBookings: React.FC = () => {
  const { setLoading } = useLoading();
  const [getReportData] = useLazyFetchReportFacilityBookingsQuery();
  const [reportData, setReportData] = useState<FacilityBookingResult[]>([]);
  const {
    control: deliveryDateControl,
    formState: { errors: deliveryDateErrors },
    watch: deliveryDateWatch,
  } = useForm<DateForm>({
    defaultValues: DateFormDefaultValues,
    mode: 'onChange',
  });

  deliveryDateWatch((data, { name }) => {
    if (data.from && data.to) {
      setupReportData(data.from, data.to);
    }
  });

  const setupReportData = async (from: Date, to: Date) => {
    try {
      setLoading(true);

      const response = await getReportData({
        from: format(new Date(from), 'yyyyMMdd'),
        to: format(new Date(to), 'yyyyMMdd'),
      }).unwrap();
      setReportData(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const bookingColumns: ColumnDefinitionType<FacilityBookingResult>[] = [
    {
      key: 'facility',
      header: 'Facility',
    },
    {
      key: 'bookingCount',
      header: 'Booking Count',
    },
    {
      key: 'bookingHourCount',
      header: 'Booked Hours',
    },
  ]; 

  return (
    <>
        <form>
          <Flex gap={8}>
            <AppDatePicker<DateForm>
              control={deliveryDateControl}
              name='from'
              label='From'
              error={deliveryDateErrors.from}
            />

            <AppDatePicker<DateForm>
              control={deliveryDateControl}
              name='to'
              label='To'
              error={deliveryDateErrors.to}
            />
          </Flex>
        </form>

      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        overflow={'hidden'}
        padding={4}
        m={4}
        flexDir={'column'}
        flex={1}
      >
        <AppDataTable
          data={reportData || []}
          noDataMessage='No data'
          showExportButton
          exportFileName={`facility-booking-report-export`}
          searchBar={false}
          columns={bookingColumns || []}
          selectableRows={false}
          showColumnVisibility={false}
        />
      </Flex>
    </>
  );
};
export default ReportAllFacilityBookings;
