import { Organization } from './organization';
import { Base } from './base';

export interface UserAuth {
  refreshToken: string;
  token: string;
  user: User;
}

export interface User extends Base {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  organizationId?: string;
  organization: Organization;
  isInvited?: boolean;
  roleId?: UserRole;
  isFirstLogin: boolean;
}

export enum UserRole {
  ADMIN,
  USER,
}

export interface UserUpsertRequest {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  organizationId?: string;
  isInvited?: boolean;
  roleId?: UserRole;
  isFirstLogin: boolean;
}

export interface PasswordUpsertRequest {
  id: string;
  password: string;
}
