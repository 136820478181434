import React, { useEffect } from 'react';
import { Flex, FlexProps, FormControl, FormLabel } from '@chakra-ui/react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { AppColors } from '../../theme';

export type AppDatePickerProps<T extends FieldValues> = FlexProps & {
  control: Control<T>;
  name: Path<T>;
  error: FieldError | undefined;
  placeHolder?: string;
  label: string;
  minDate?: Date;
};

export const AppDatePicker = <T extends FieldValues>({
  control,
  name,
  error,
  placeHolder,
  label,
  minDate,
  ...props
}: AppDatePickerProps<T>) => {
  useEffect(() => {
    if (error) {
      console.log(error);
    }
  }, [error]);

  return (
    <Flex {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl>
            <FormLabel>{label}</FormLabel>
            <SingleDatepicker
              name="date-input"
              date={value}
              minDate={minDate}
              onDateChange={onChange}
              configs={{
                dateFormat: 'dd MMM yyyy',
              }}
              propsConfigs={{
                dateNavBtnProps: {
                  colorScheme: AppColors.primary,
                },
                dayOfMonthBtnProps: {
                  defaultBtnProps: {
                    _hover: {
                      background: AppColors.secondary,
                      color: AppColors.innerContentColor,
                    },
                  },
                  selectedBtnProps: {
                    background: AppColors.secondary,
                    color: AppColors.innerContentColor,
                  },
                  todayBtnProps: {
                    background: AppColors.tertiary,
                    color: AppColors.innerContentColor,
                    _hover: {
                      background: AppColors.secondary,
                      color: AppColors.innerContentColor,
                    },
                  },
                },
                inputProps: {
                  size: 'md',
                  borderRadius: '0',
                },
                popoverCompProps: {
                  popoverBodyProps: {
                    p: '1px',
                    borderWidth: 2,
                  },
                  popoverContentProps: {
                    background: AppColors.innerContentColor,
                    color: AppColors.primary,
                  },
                },
              }}
            />
            {error && <div style={{ color: 'red' }}>{error?.message}</div>}
          </FormControl>
        )}
      />
    </Flex>
  );
};
