import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppFetchBaseQuery } from './apiBase';
import {
  Contacts,
  ContactsForm,
  ContactsEditForm,
} from '../../models/contacts';
import { PaginatedData, ByOptionsRequest } from '@giflo/shared';

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchContactsByOptions: build.query<
      PaginatedData<Contacts>,
      ByOptionsRequest
    >({
      query: (data) => ({
        url: `/contact/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<Contacts>) => {
        return response;
      },
    }),
    fetchAllContacts: build.query<Contacts[], any>({
      query: () => ({
        url: '/contact',
        method: 'GET',
      }),
    }),
    fetchContactById: build.query<Contacts, string>({
      query: (id) => ({
        url: `/contact/${id}`,
        method: 'GET',
      }),
    }),
    createContact: build.mutation<Contacts, ContactsForm>({
      query: (body) => ({
        url: '/contact/create',
        method: 'POST',
        body: body,
      }),
    }),
    updateContact: build.mutation<Contacts, ContactsEditForm>({
      query: (body) => ({
        url: `/contact/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Contacts) => {
        return response;
      },
    }),
    deleteContact: build.mutation<string, string>({
      query: (id) => ({
        url: `/contact/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchContactsByOptionsQuery,
  useFetchAllContactsQuery,
  useFetchContactByIdQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
} = contactsApi;
