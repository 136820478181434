import { createApi } from '@reduxjs/toolkit/query/react';
import {
  Organization,
  OrganizationEditForm,
  OrganizationFacilityMappingRequest,
  OrganizationFormData,
  OrganizationsByOptionsRequest,
  PaginatedData,
} from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationsApi = createApi({
  reducerPath: 'organizationsApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchOrganizationsByOptions: build.query<
      PaginatedData<Organization>,
      OrganizationsByOptionsRequest
    >({
      query: (data) => ({
        url: `/organization/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<Organization>) => {
        return {
          data: response?.data?.filter((x) => x.isActive === true),
          pagination: response.pagination,
        };
      },
    }),
    fetchAllOrganizations: build.query<Organization[], any>({
      query: () => ({
        url: '/organization',
        method: 'GET',
      }),
      transformResponse: (response: Organization[]) => {
        return response?.filter((x) => x.isActive === true);
      },
    }),
    fetchOrganizationCount: build.query<number, any>({
      query: () => ({
        url: '/organization/count',
        method: 'GET',
      }),
      transformResponse: (response: number) => {
        return response;
      },
    }),
    fetchTopOrganization: build.query<Organization, any>({
      query: () => ({
        url: `/organization/top/organization`,
        method: 'GET',
      }),
    }),
    fetchOrganizationById: build.query<Organization, string>({
      query: (id) => ({
        url: `/organization/${id}`,
        method: 'GET',
      }),
    }),
    allocateOrganizationFacilities: build.mutation<
      Organization,
      OrganizationFacilityMappingRequest
    >({
      query: (body) => ({
        url: `/organization/facility/mapping`,
        method: 'POST',
        body: body,
      }),
    }),
    createOrganization: build.mutation<Organization, OrganizationFormData>({
      query: (body) => ({
        url: '/organization/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    updateOrganization: build.mutation<Organization, OrganizationEditForm>({
      query: (body) => ({
        url: `/organization/update/${body.id}`,
        method: 'PUT',
        body: body,
      }),
      transformResponse: (response: Organization) => {
        return response;
      },
    }),
    deleteOrganization: build.mutation<string, string>({
      query: (id) => ({
        url: `/organization/delete/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteOrganizationOverride: build.mutation<string, string>({
      query: (id) => ({
        url: `/organization/delete/${id}/override`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchOrganizationsByOptionsQuery,
  useFetchAllOrganizationsQuery,
  useFetchOrganizationCountQuery,
  useFetchTopOrganizationQuery,
  useLazyFetchAllOrganizationsQuery,
  useFetchOrganizationByIdQuery,
  useAllocateOrganizationFacilitiesMutation,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useDeleteOrganizationOverrideMutation,
} = organizationsApi;
