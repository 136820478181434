import React, { useState } from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactsForm } from '../../models/contacts';
import { AppInput, AppButton, AppColors, AppAlertItem } from '@giflo/shared';
import { BaseFormProps } from './base-forms';

const contactFormDefaultValues: ContactsForm = {
  name: '',
  emailAddress: '',
  companyName: '',
  number: '',
};

const contactFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  emailAddress: yup.string(),
  companyName: yup.string().required('Field is required'),
  number: yup.string().required('Field is required'),
});

type ContactFormProps<T> = {
  form?: ContactsForm;
} & BaseFormProps<T>;

const ContactForm: React.FC<ContactFormProps<ContactsForm>> = ({
  isLoading,
  form,
  onSubmit,
}) => {
  const {
    control: control,
    handleSubmit,
    getValues,
    formState: { isValid, errors },
  } = useForm<ContactsForm>({
    defaultValues: form || contactFormDefaultValues,
    resolver: yupResolver(contactFormDataSchema),
    mode: 'onChange',
  });

  const [contactWarning, setContactWarning] = useState<string>('none');

  const handleFormSubmit = () => {
    const formValues = getValues();
    let isFormValid = true;

    if (!formValues.emailAddress && !formValues.number) {
      setContactWarning('flex');
      isFormValid = false;
      setTimeout(() => {
        setContactWarning('none');
      }, 5000);
    }
    if (!isFormValid) return;
    return handleContactFormSave();
  };

  const handleContactFormSave = () => {
    const formValues = getValues();
    onSubmit(formValues)
  }
  return (
    <Stack spacing={4}>
      <AppInput<ContactsForm>
        name='companyName'
        control={control}
        error={errors.companyName}
        label='Organization'
      />
      <AppInput<ContactsForm>
        name='name'
        control={control}
        error={errors.name}
        label='Name'
      />
      <AppInput<ContactsForm>
        name='emailAddress'
        control={control}
        error={errors.emailAddress}
        label='Email'
      />
      <AppInput<ContactsForm>
        name='number'
        control={control}
        error={errors.number}
        label='Cell No.'
      />
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        
        onClick={handleFormSubmit}
      >
        Save
      </AppButton>
      <Flex display={contactWarning}>
        <AppAlertItem
          item={{
            name: 'Please enter an email or number',
            nameLabel: undefined,
            alertType: 'warning',
            alertMessage: 'Field is required',
            alertMessageLabel: undefined,
            alertNote: undefined,
            alertNoteLabel: undefined,
            entityId: undefined,
            alertKey: undefined,
          }}
        />
      </Flex>
    </Stack>
  );
};

export default ContactForm;
