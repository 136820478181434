import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Stack,
  Image,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  FormLabel,
  Flex,
  List,
  ListItem,
  Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppAlertItem,
  AppButton,
  AppColors,
  AppInput,
  getCompressedImage,
  FacilityForm,
  FacilityFormImage,
  LineItem,
  SelectListFilter,
  SelectOption,
  Time,
  RoomType,
  User,
  DaysOfTheWeek,
  FacilityAvailableDayDto,
  AppCheckbox,
  SelectedDay,
} from '@giflo/shared';
import { BaseFormProps } from './base-forms';
import { AppImageUploader } from '@giflo/shared';
import { AppMarkdown } from '@giflo/shared';
import { DeleteIcon } from '@chakra-ui/icons';
import { useFetchAllusersQuery } from '../../store/api/userApi';

const facilityFormDefaultValues: FacilityForm = {
  name: '',
  description: '',
  eventColor: AppColors.primary,
  images: undefined,
  lineItems: [],
  capacity: undefined,
  start: undefined,
  end: undefined,
  minDuration: undefined,
  roomType: undefined,
  ownerId: '',
};
const facilityFormDataSchema = yup.object({
  name: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  capacity: yup.number().required('Field is required'),
  minDuration: yup.number(),
  roomType: yup.number(),
});

type FacilityFormProps<T> = {
  form?: FacilityForm;
} & BaseFormProps<T>;

const FacilitiesForm: React.FC<FacilityFormProps<FacilityForm>> = ({
  isLoading,
  form,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, errors },
  } = useForm<FacilityForm>({
    defaultValues: form || facilityFormDefaultValues,
    resolver: yupResolver(facilityFormDataSchema),
    mode: 'onChange',
  });
  const { data: users, refetch: refetchUsers } = useFetchAllusersQuery({
    refetchOnMountArgChange: true,
  });
  const minuteArray: number[] = [0, 15, 30, 45];
  const durationOptions = [15, 30, 45, 1, 2, 3, 4, 5, 6, 7, 8];

  const [files, setFiles] = useState<FacilityFormImage[] | null>(null);
  const [listItem, setListItem] = useState<string>('');
  const [listItems, setListItems] = useState<LineItem[]>([]);
  const [currentHourOptions, setCurrentHourOptions] = useState<SelectOption[]>(
    []
  );
  const [endMinutes, setEndMinutes] = useState<SelectOption[]>([]);
  const [roomTypeOptions, setRoomTypeOptions] = useState<SelectOption[]>([]);

  const [facilityImageWarning, setFacilityImageWarning] =
    useState<string>('none');
  const [mainImageWarning, setMainImageWarning] = useState<string>('none');
  const [durationSlots, setDurationSlots] = useState<SelectOption[]>([]);

  const [ownerOptions, setOwnerOptions] = useState<SelectOption[]>();

  const [availableDays, setAvailableDays] =
    useState<SelectedDay[]>();
  const openDays = Object.keys(DaysOfTheWeek) as Array<
    keyof typeof DaysOfTheWeek
  >;
  const days: FacilityAvailableDayDto[] = [];

  const hourOptions = () => {
    const hours = [];
    for (let i = 0; i < 25; i++) {
      hours.push(i);
    }
    return hours;
  };

  useEffect(() => {
    const selectors: SelectOption[] = [
      { label: 'Meeting Room', value: RoomType.MEETING_ROOM },
      { label: 'Klubhuis', value: RoomType.CLUB_HOUSE },
      { label: 'Barber', value: RoomType.BARBER },
    ];
    setRoomTypeOptions(selectors);
    const copyDays = openDays.map((day) => {
      return {
        name: day,
        selected: false
      }
    })
    setAvailableDays(copyDays)
  }, []);

  useEffect(() => {
    const selectors: SelectOption[] = [];
    durationOptions.forEach((x) => {
      selectors.push({
        label: x < 10 ? `${x}h` : `${x}m`,
        value:
          x < 10
            ? ({ hour: x, minute: 0 } as Time)
            : ({ hour: 0, minute: x } as Time),
      });
    });
    setDurationSlots(selectors);
  }, []);

  useEffect(() => {
    const userSelectors: SelectOption[] = [];
    users?.map((x) => {
      userSelectors.push({
        label: `${x.lastName}, ${x.firstName}`,
        value: x.id,
      });
    });
    setOwnerOptions(userSelectors);
  }, [users]);

  useEffect(() => {
    const currentHours = hourOptions();
    const selection = currentHours.map((x) => {
      return { label: `${x}`, value: `${x}` } as SelectOption;
    });
    setCurrentHourOptions(selection);
  }, []);

  useEffect(() => {
    const minuteOptions: SelectOption[] = [];
    minuteArray.map((x) => {
      const selector = { label: `${x}`, value: `${x}` } as SelectOption;
      minuteOptions.push(selector);
      return minuteOptions;
    });
    setEndMinutes(minuteOptions);
  }, []);

  const mainImage = files?.filter((x) => x.isMain === true);
  const handleImageSelect = (url: string) => {
    const copyFiles = files!.map((x) => {
      if (x.imageUrl === url) {
        return { ...x, isMain: true };
      }
      return x;
    });
    setFiles(copyFiles);
  };

  const getBase64 = async (file: File): Promise<string> => {
    const compressedFile = await getCompressedImage(file);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFiles = Array.from(e.target.files!);
    const newFilesPromises = currentFiles.map(async (file) => {
      const base64String = await getBase64(file);
      const splitString = base64String.split('base64,')[1];
      const image: FacilityFormImage = {
        imageUrl: splitString,
        rawImage: base64String,
        isMain: false,
      };
      return image;
    });
    const copyFiles: FacilityFormImage[] = JSON.parse(
      JSON.stringify(files ?? [])
    );
    const newFiles: FacilityFormImage[] = await Promise.all(newFilesPromises);
    copyFiles.push(...newFiles);
    setFiles(copyFiles);
  };

  const handleAddItem = (item: string) => {
    if (listItem) {
      setListItems((listItems) => {
        return [...listItems, { lineItem: listItem } as LineItem];
      });
    }
    setListItem('');
  };

  const handleDeleteItem = (item: LineItem) => {
    const newList = listItems.filter((x) => x !== item);
    setListItems(newList);
  };

  const handleImageRemove = (index: number) => {
    const copyFiles: FacilityFormImage[] = JSON.parse(JSON.stringify(files));
    copyFiles.splice(index, 1);
    setFiles(copyFiles);
  };

  const onSubmitForm = () => {
    let isFormValid = true;

    if (!files?.length) {
      setFacilityImageWarning('flex');
      isFormValid = false;
      setTimeout(() => {
        setFacilityImageWarning('none');
      }, 5000);
    }
    if (!mainImage?.length) {
      setMainImageWarning('flex');
      isFormValid = false;
      setTimeout(() => {
        setMainImageWarning('none');
      }, 5000);
    }
    if (!isFormValid) return;
    return handleFacilityFormSave();
  };

  const handleFacilityFormSave = () => {
    setValue('lineItems', listItems);
    setValue('images', files ?? []);
    setValue('availableDaysOfTheWeek', days)
    const formData = getValues();
    console.log('form data', formData)
    
    onSubmit(formData);
  };

  const handleCheckboxSelect = (index: number) => {
    switch (index) {
      case 0:
        const sunday = {
          day: DaysOfTheWeek.Sunday
        }
        days.push(sunday);
        break;
      case 1:
        const monday = {
          day: DaysOfTheWeek.Monday,
        }
        days.push(monday);
        break;
      case 2:
        const tuesday = {
          day: DaysOfTheWeek.Tuesday,
        }
        days.push(tuesday);
        break;
      case 3:
        const wednesday = {
          day: DaysOfTheWeek.Wednesday,
        }
        days.push(wednesday);
        break;
      case 4:
        const thursday = {
          day: DaysOfTheWeek.Thursday,
        }
        days.push(thursday);
        break;
      case 5:
        const friday = {
          day: DaysOfTheWeek.Friday,
        }
        days.push(friday);
        break;
      case 6:
        const saturday = {
          day: DaysOfTheWeek.Saturday,
        }
        days.push(saturday);
        break;
    }
  };

  return (
    <Stack spacing={3}>
      <FormLabel>Images</FormLabel>
      <AppImageUploader
        name='images'
        error={undefined}
        inputType='file'
        onChange={handleChange}
      />
      <Grid
        gridTemplateColumns={'repeat(2, 1fr)'}
        gridColumnGap={2}
        gridRowGap={2}
        w={'full'}
      >
        {files?.map((image: FacilityFormImage, index: number) => {
          return (
            <GridItem key={index}>
              <RadioGroup>
                <Radio
                  type='radio'
                  name='mainImage'
                  value={image.imageUrl}
                  onClick={() => handleImageSelect(image.imageUrl)}
                />
                <Image
                  src={image.rawImage ? image.rawImage : image.imageUrl}
                  borderRadius={'md'}
                />
                <AppButton
                  bgColor={AppColors.primary}
                  color={'white'}
                  onClick={() => handleImageRemove(index)}
                  size={'sm'}
                  w={'100%'}
                  fontSize={'12px'}
                >
                  Remove Image
                </AppButton>
              </RadioGroup>
            </GridItem>
          );
        })}
      </Grid>
      <FormLabel m={0}>Facility Owner</FormLabel>
      <SelectListFilter
        options={ownerOptions}
        isInModal
        showClear={false}
        name='ownerId'
        w={'full'}
        placeholder='Facility Owner'
        isMulti={false}
        onSelectionChange={(item: SelectOption[]) => {
          setValue('ownerId', item[0].value);
        }}
      />
      <AppInput<FacilityForm>
        name='name'
        control={control}
        label='Name:'
        error={errors.name}
      />
      <AppMarkdown<FacilityForm>
        name='description'
        control={control}
        label='Description'
        error={errors.description}
      />

      {/* <AppFormColorField
        setValue={setValue}
        currentColor={''}
        label={'Event Color'}
        nameProp={'eventColor'}
        register={register}
      /> */}

      <AppInput<FacilityForm>
        pt={12}
        name='capacity'
        control={control}
        inputType={'number'}
        label='Facility Capacity:'
        error={errors.capacity}
      />

      <SelectListFilter
        w={'full'}
        name='roomType'
        options={roomTypeOptions}
        isInModal
        isMulti={false}
        placeholder='Room Type'
        onSelectionChange={(item: SelectOption[]) => {
          setValue('roomType', +item[0].value!);
        }}
        showClear={false}
      />
      <Flex direction={'column'} gap={3} w={'full'}>
        <FormLabel p={0} m={0}>
          Available Days
        </FormLabel>
        <Flex w={'full'} align={'center'} justify={'space-evenly'}>
          {openDays.map((day, i) => {
            return (
              <AppCheckbox<FacilityForm>
                key={i}
                control={control}
                error={undefined}
                label={day}
                name='availableDaysOfTheWeek'
                onChange={() => {
                  handleCheckboxSelect(i);
                }}
              />
            );
          })}
        </Flex>
      </Flex>

      <FormLabel>Open from</FormLabel>
      <Flex w={'full'} gap={3}>
        <SelectListFilter
          w={'50%'}
          name='start'
          options={currentHourOptions}
          isInModal
          isMulti={false}
          placeholder='hour'
          onSelectionChange={(item: SelectOption[]) => {
            setValue('start.hour', +item[0].value!);
          }}
          showClear={false}
        />
        <SelectListFilter
          w={'50%'}
          name='start'
          options={endMinutes}
          isInModal
          isMulti={false}
          placeholder='minute'
          onSelectionChange={(item: SelectOption[]) => {
            setValue('start.minute', +item[0].value!);
          }}
          showClear={false}
        />
      </Flex>
      <FormLabel>Closing</FormLabel>
      <Flex w={'full'} gap={3}>
        <SelectListFilter
          w={'50%'}
          name='end'
          options={currentHourOptions}
          isInModal
          isMulti={false}
          placeholder='hour'
          onSelectionChange={(item: SelectOption[]) => {
            setValue('end.hour', +item[0].value!);
          }}
          showClear={false}
        />
        <SelectListFilter
          w={'50%'}
          name='end'
          options={endMinutes}
          isInModal
          isMulti={false}
          placeholder='minute'
          onSelectionChange={(item: SelectOption[]) => {
            setValue('end.minute', +item[0].value!);
          }}
          showClear={false}
        />
      </Flex>

      <FormLabel>Min Duration</FormLabel>
      <SelectListFilter
        w={'full'}
        name='minDuration'
        options={durationSlots}
        isInModal
        isMulti={false}
        placeholder='Min Duration'
        onSelectionChange={(item: SelectOption[]) => {
          setValue('minDuration', item[0].value);
        }}
        showClear={false}
      />

      <FormLabel>Line Items</FormLabel>
      <Flex w={'full'} gap={3}>
        <Input
          value={listItem}
          onChange={(e) => setListItem(e.target.value)}
          name='list'
          placeholder='list'
        />
        <AppButton onClick={() => handleAddItem(listItem)}>+</AppButton>
      </Flex>
      <List spacing={4}>
        {listItems.map((x) => {
          return (
            <Flex
              w={'full'}
              justify={'space-between'}
              align={'center'}
              gap={3}
              key={x.lineItem}
            >
              <Flex gap={3}>
                <ListItem>{x.lineItem}</ListItem>
              </Flex>
              <AppButton
                size={'sm'}
                rightIcon={<DeleteIcon />}
                borderRadius={'full'}
                onClick={() => handleDeleteItem(x)}
              >
                Delete
              </AppButton>
            </Flex>
          );
        })}
      </List>
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        onClick={onSubmitForm}
        // isDisabled={!isValid}
      >
        Save
      </AppButton>
      <Flex display={facilityImageWarning}>
        {!files?.length ? (
          <AppAlertItem
            item={{
              name: 'Facility Images required',
              nameLabel: undefined,
              alertType: 'warning',
              alertMessage: 'Field is required',
              alertMessageLabel: undefined,
              alertNote: undefined,
              alertNoteLabel: undefined,
              entityId: undefined,
              alertKey: undefined,
            }}
          />
        ) : (
          ''
        )}
      </Flex>
      <Flex display={mainImageWarning}>
        {!mainImage?.length ? (
          <AppAlertItem
            item={{
              name: 'Please select a main image',
              nameLabel: undefined,
              alertType: 'warning',
              alertMessage: 'Field is required',
              alertMessageLabel: undefined,
              alertNote: undefined,
              alertNoteLabel: undefined,
              entityId: undefined,
              alertKey: undefined,
            }}
          />
        ) : (
          ''
        )}
      </Flex>
    </Stack>
  );
};

export default FacilitiesForm;
