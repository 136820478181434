import { Flex, useColorModeValue } from '@chakra-ui/react';
import {
  AppColors,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  AppPaginator,
  SelectListFilter,
  SelectOption,
  AppLoader,
  Booking,
  AppText,
  useLoading,
  useDialog,
  AppButton,
} from '@giflo/shared';
import { AppDataTable } from '@giflo/shared';
import React, { useEffect, useState } from 'react';
import { useFetchAllFacilitiesQuery } from '../../../store/api/facilityApi';
import { useFetchOrganizationBookingsByOptionsQuery } from '../../../store/api/organizationBookingApi';
import { useFetchAllOrganizationsQuery } from '../../../store/api/organizations';
import { useFetchAllusersQuery } from '../../../store/api/userApi';
import { format } from 'date-fns';
const { JSONtoCSVConverter } = require('react-json-csv-convert');

const ReportAllBookings: React.FC = () => {
  const dialog = useDialog();
  const { data: organizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });
  const { setLoading } = useLoading();

  const { data: facilities } = useFetchAllFacilitiesQuery({
    refetchOnMountArgChange: true,
  });

  const { data: users } = useFetchAllusersQuery({
    refetchOnMountArgChange: true,
  });

  const [organizationOptions, setOrganizationOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    string | undefined
  >(undefined);

  const [facilityOptions, setFacilityOptions] = useState<SelectOption[]>([]);
  const [selectedFacilityId, setSelectedFacilityId] = useState<
    string | undefined
  >(undefined);

  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined
  );
  const [selectedBookings, setSelectedBookings] = useState<Booking[]>();

  useEffect(() => {
    if (organizations) {
      const options: SelectOption[] = organizations.map((x) => {
        return {
          label: x.isActive === false ? `${x.name} (InActive)` : x.name,
          value: x.id,
        };
      });

      setOrganizationOptions(options);
    }
  }, [organizations]);

  useEffect(() => {
    if (facilities) {
      const options: SelectOption[] = facilities.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });

      setFacilityOptions(options);
    }
  }, [facilities]);

  useEffect(() => {
    if (users) {
      const options: SelectOption[] = users.map((x) => {
        return {
          label: `${x.firstName} ${x.lastName}`,
          value: x.id,
        };
      });

      setUserOptions(options);
    }
  }, [users]);

  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
  } = useDataTable<Booking>();

  const { data: bookingResponse, isFetching } =
    useFetchOrganizationBookingsByOptionsQuery(
      {
        facilityId: selectedFacilityId,
        organizationId: selectedOrganizationId,
        userId: selectedUserId,
        pageNumber: nextPaginationQueryParams.page,
        pageSize: nextPaginationQueryParams.pageSize,
        sortParams: nextPaginationQueryParams.sortList,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (bookingResponse?.pagination) {
      setPagination(bookingResponse?.pagination);
    }
  }, [bookingResponse]);

  const bookingColumns: ColumnDefinitionType<Booking>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Created',
      headerSortable: true,
    },
    {
      key: 'day',
      type: ColumnSpecialTypes.date,
      header: 'Booked Date',
      headerSortable: true,
    },
    {
      key: 'organizationId',
      header: 'Organization',
      render: (item) => <AppText>{item.organization?.name}</AppText>,
    },
    {
      key: 'facilityId',
      header: 'Facility',
      render: (item) => <AppText>{item.facility?.name}</AppText>,
    },
    {
      key: 'start',
      header: 'Time',
      render: (item) => (
        <AppText color={AppColors.tertiary}>
          {`${item.start?.hour}:${
            item.start?.minute === 0 ? '00' : item.start?.minute
          } - ${item.end?.hour}:${
            item.end?.minute === 0 ? '00' : item.end?.minute
          }`}
        </AppText>
      ),
    },
  ];

  const handleRowsSelected = (items: Booking[]) => {
    setSelectedBookings(items);
  };

  const setupExportLoader = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleExportCSVBookingClicked = () => {
    const exportData = selectedBookings?.map((booking) => {
      return {
        day: booking?.day ?? '',
        organizationId: booking?.organization?.name ?? '',
        facilityId: booking?.facility?.name ?? '',
        time:
          `${booking.start!.hour}:${booking.start!.minute}-${
            booking.end!.hour
          }:${booking.end!.minute}` ?? '',
      };
    });

    const headers = ['BOOKING DATE', 'ORGANIZATION', 'FACILITY', 'TIME'];
    const csvConfig = {
      headers,
      keys: ['day', 'organizationId', 'facilityId', 'time'],
      actions: Object.keys(headers).map((x) => null),
    };

    dialog({
      title: 'Download Excel',
      render: (onSubmit, onCancel) => {
        return (
          <JSONtoCSVConverter
            csvConfig={csvConfig}
            data={exportData}
            styleProp={{ display: 'inline-block' }}
            fileName={`report-export-${format(new Date(), 'dd MMM yyyy')}`}
          >
            <AppButton
              bgColor={AppColors.primary}
              mr="4"
              color={'white'}
              onClick={() => {
                setupExportLoader();
                onSubmit();
              }}
            >
              Yes
            </AppButton>
          </JSONtoCSVConverter>
        );
      },
    });
  };

  return (
    <>
      {isFetching && <AppLoader />}
      <Flex justifyContent={'space-between'} m={4}>
        <Flex align={'center'} gap={4}>
          <SelectListFilter
            w={'220px'}
            options={organizationOptions}
            isInModal
            isMulti={false}
            placeholder="Organization Filter"
            onSelectionChange={async (item: SelectOption[]) => {
              if (item && item.length > 0) {
                setSelectedOrganizationId(item[0].value);
              } else {
                setSelectedOrganizationId(undefined);
              }
            }}
            showClear={true}
          />
          <SelectListFilter
            w={'220px'}
            options={facilityOptions}
            isInModal
            isMulti={false}
            placeholder="Facility Filter"
            onSelectionChange={async (item: SelectOption[]) => {
              if (item && item.length > 0) {
                setSelectedFacilityId(item[0].value);
              } else {
                setSelectedFacilityId(undefined);
              }
            }}
            showClear={true}
          />
          <SelectListFilter
            w={'220px'}
            options={userOptions}
            isInModal
            isMulti={false}
            placeholder="User Filter"
            onSelectionChange={async (item: SelectOption[]) => {
              if (item && item.length > 0) {
                setSelectedUserId(item[0].value);
              } else {
                setSelectedUserId(undefined);
              }
            }}
            showClear={true}
          />
        </Flex>
        <Flex alignItems={'center'}>
          <AppButton
            bgColor={AppColors.tertiary}
            color={'white'}
            onClick={handleExportCSVBookingClicked}
            size="sm"
            borderRadius={0}
            mr="4"
            isDisabled={selectedBookings?.length! > 0 ? false : true}
          >
            Export Selected Items To Excel
          </AppButton>
        </Flex>
      </Flex>

      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'2xl'}
        overflow={'hidden'}
        m={4}
        flexDir={'column'}
        flex={1}
        pb={8}
      >
        <AppDataTable
          data={bookingResponse?.data || []}
          noDataMessage="No bookings"
          searchBar={false}
          columns={bookingColumns || []}
          selectableRows={true}
          onRowSelected={handleRowsSelected}
          showColumnVisibility={false}
          onSortByColumnClick={handleSortByColumnClick}
        />
        <AppPaginator
          pagination={pagination}
          onPageChange={handleOnPageChange}
        ></AppPaginator>
      </Flex>
    </>
  );
};
export default ReportAllBookings;
