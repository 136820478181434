import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserAuth } from '@giflo/shared';
import { RootState } from '../store';

interface AuthenticationState {
  user: User | null;
  token: string | null;
  error: string | null;
}

const initialState: AuthenticationState = {
  user: null,
  token: null,
  error: null,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: (state) => {
      return { ...state, user: null, token: null, error: null };
    },
    setUserAuth: (state, action: PayloadAction<UserAuth>) => {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        error: null,
      };
    },
    setUser: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        user: action.payload,
      };
    },
  },
});

export const { logout, setUserAuth, setUser } = authenticationSlice.actions;

export const selectUser = (state: RootState) => state.authentication.user;
export const selectToken = (state: RootState) => state.authentication.token;
export const isAuthenticated = (state: RootState) =>
  state.authentication.user &&
  state.authentication?.token &&
  state.authentication?.token?.length > 0;
