import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
  Link,
} from '@chakra-ui/react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { RiDashboardFill } from 'react-icons/ri';
import logo from '../../assets/Logo White and Blue.png';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectUser } from '../../store/slices/authentication.slice';
import { RoutesList } from '../../router/router';
import { useNavigate } from 'react-router-dom';
import { NavLink as RouterLink } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { ReactNode } from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { AiFillCalendar, AiFillContacts, AiFillMessage } from 'react-icons/ai';
import { GoOrganization } from 'react-icons/go';
import { BsFillHouseDoorFill } from 'react-icons/bs';
import { LinkItemProps } from '../../models/general';
import { NavItemCollapse } from './nav-item-collapse';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { AppColors } from '@giflo/shared';

var packageJson = require('../../../package.json');

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

export type BaseLayoutProps = {
  children?: ReactNode | ReactNode[];
};

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  const version = packageJson.version;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = useSelector(selectUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout());
    navigate(RoutesList.Index);
  };

  const handleUserProfileClick = () => {
    navigate(RoutesList.UserProfile);
  };

  const LinkItems: Array<LinkItemProps> = [
    { name: 'Dashboard', path: RoutesList.Dashboard, icon: RiDashboardFill },
    { name: 'User Management', path: RoutesList.Users, icon: FaUserFriends },
    {
      name: 'Facilities',
      path: RoutesList.Facilities,
      icon: BsFillHouseDoorFill,
    },
    {
      name: 'Organizations',
      path: RoutesList.Organizations,
      icon: GoOrganization,
    },
    {
      name: 'Contacts',
      path: RoutesList.Contacts,
      icon: AiFillContacts,
    },
    {
      name: 'Activities',
      path: RoutesList.Activity,
      icon: AiFillMessage,
    },
    {
      name: 'Bookings',
      path: RoutesList.Bookings,
      icon: AiFillCalendar,
    },
    {
      name: 'Reporting',
      path: RoutesList.Reports,
      icon: HiOutlineDocumentReport,
    },
  ];

  const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
      <Box
        transition="3s ease"
        bg={AppColors.primary}
        borderRight="1px"
        borderRightColor={AppColors.primary}
        w={{ base: 'full', md: '80' }}
        pos="fixed"
        h="full"
        {...rest}
      >
        <Flex
          h="20"
          alignItems="center"
          mx="8"
          justifyContent={{ base: 'space-between', md: 'center' }}
        >
          <Image mt={6} src={logo} alt="" height={'80px'} />
          <CloseButton
            size={'lg'}
            color={'white'}
            display={{ base: 'flex', md: 'none' }}
            onClick={onClose}
          />
        </Flex>
        <Box mt={8}>
          {LinkItems.map((link) =>
            link.children ? (
              <NavItemCollapse
                key={link.name}
                name={link.name}
                children={link.children}
                icon={link.icon}
              />
            ) : (
              <NavItem
                key={link.name}
                name={link.name}
                path={link.path}
                icon={link.icon}
              />
            )
          )}
        </Box>
      </Box>
    );
  };

  const NavItem = ({ name, path, icon }: LinkItemProps) => {
    return (
      <Link
        as={RouterLink}
        to={path ?? ''}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'white',
            color: AppColors.primary,
          }}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              color={'white'}
              _groupHover={{
                color: AppColors.primary,
              }}
              as={icon}
            />
          )}
          <Box
            color={'white'}
            _groupHover={{
              color: AppColors.primary,
            }}
          >
            {name}
          </Box>
        </Flex>
      </Link>
    );
  };

  const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={{
          base: AppColors.primary,
          md: useColorModeValue('white', 'gray.900'),
        }}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent={{ base: 'space-between', md: 'flex-end' }}
        {...rest}
      >
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: 'flex', md: 'none' }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
        >
          <Image src={logo} alt="" height={'60px'} />
        </Text>

        <HStack spacing={{ base: '0', md: '6' }}>
          {/* <IconButton
            size="lg"
            variant="ghost"
            aria-label="open menu"
            icon={<FiBell />}
          /> */}
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: 'none' }}
              >
                <HStack>
                  <Flex
                    h={'10'}
                    w={'10'}
                    borderRadius={'100%'}
                    bg={AppColors.secondary}
                    border={`1px solid ${AppColors.appBorder}`}
                    color={'white'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Icon as={FaUserCircle} boxSize={'1em'} color={'white'} />
                  </Flex>
                  <VStack
                    display={{ base: 'none', md: 'flex' }}
                    alignItems="flex-start"
                    spacing="1px"
                    ml="2"
                  >
                    <Text fontSize="sm">
                      {user?.firstName} {user?.lastName}
                    </Text>
                    <Text fontSize="xs" color="gray.600">
                      {user?.email}
                    </Text>
                  </VStack>
                  <Box display={{ base: 'none', md: 'flex' }}>
                    <FiChevronDown />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                bg={useColorModeValue('white', 'gray.900')}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
              >
                {/* <MenuItem onClick={handleUserProfileClick}>Profile</MenuItem>
                <MenuDivider /> */}
                <MenuItem onClick={onLogout}>Sign out</MenuItem>
                <MenuDivider />

                <Text fontSize={'small'} pr={2} textAlign={'end'}>
                  Version: {version}
                </Text>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    );
  };

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Flex
        ml={{ base: 0, md: 80 }}
        id="base-layout-container"
        overflowY={'auto'}
        maxH={'90vh'}
        pb={12}
      >
        {children}
      </Flex>
    </Box>
  );
};

export default BaseLayout;
