import BaseLayout from '../../components/layouts/base-layout';
import {
  Flex,
  Box,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  useToast,
  Text,
  Image,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import {
  AppButton,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  usePanel,
  useLoading,
  Facility,
  FacilityForm,
  FacilityFormImage,
  FacilityImage,
  useDialog,
  AppConfirm,
  useDataTable,
  AppPaginator,
  AppText,
  DaysOfTheWeek,
  SelectedDay,
  AppLoader,
} from '@giflo/shared';
import { AppDataTable } from '@giflo/shared';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import { AppColors } from '@giflo/shared';
import {
  useCreateFacilityMutation,
  useEditFacilityMutation,
  useDeleteFacilityMutation,
  useFetchFacilitiesByOptionsQuery,
} from '../../store/api/facilityApi';
import FacilitiesForm from '../../components/forms/facility-form';
import FacilitiesEditForm from '../../components/forms/facility-edit-form';
import { useFileUploadMutation } from '../../store/api/assetApi';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/authentication.slice';
import { object } from 'yup';

const Facilities: React.FC = () => {
  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
    handleSearchChange,
  } = useDataTable<Facility>();

  const {
    data: facilities,
    isLoading,
    refetch: refetchFacilities,
  } = useFetchFacilitiesByOptionsQuery(
    {
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (facilities?.pagination) {
      setPagination(facilities?.pagination);
    }
  }, [facilities]);

  const panel = usePanel();
  const dialog = useDialog();
  const { setLoading } = useLoading();
  const toast = useToast();
  const [saveFacilityTriger] = useCreateFacilityMutation();
  const [editFacilityTrigger] = useEditFacilityMutation();
  const [uploadFile] = useFileUploadMutation();
  const [deleteFacilityTrigger] = useDeleteFacilityMutation();

  const handleAddDialog = () => {
    panel({
      size: 'xl',
      title: 'Add Facility',
      render: (onSubmit) => {
        return (
          <FacilitiesForm
            onSubmit={async (formData) => {
              onSubmit();
              await addFacility(formData);
            }}
          />
        );
      },
    });
  };

  const handleEditDialog = (facility: Facility) => {
    const images: FacilityFormImage[] | undefined = facility?.images?.map(
      (x) => {
        return { imageUrl: x.imageUrl, isMain: x.isMain };
      }
    );
    const facilityForm: FacilityForm = {
      id: facility.id,
      name: facility.name,
      description: facility.description!,
      eventColor: facility.eventColor,
      images: images ?? [],
      lineItems: facility.lineItems!,
      capacity: facility.capacity,
      start: facility.start,
      end: facility.end,
      ownerId: facility.ownerId ?? '',
      roomType: facility.roomType ?? undefined,
    };
    const days = Object.keys(DaysOfTheWeek) as Array<
      keyof typeof DaysOfTheWeek
    >;
    const dayOptions: string[] = [...days];
    const selectedDays = facility?.availableDaysOfTheWeek?.map((x) => {
      return `${x.day}`;
    }) as string[];

    const daysToCheck: SelectedDay[] = dayOptions.map((x) => {
      const currentDays = selectedDays.find((day) => {
        return x === day;
      });
      return {
        name: x,
        selected: currentDays ? true : false,
      };
    });

    panel({
      title: 'Edit Facility',
      size: 'xl',
      render: (onSubmit) => {
        return (
          <FacilitiesEditForm
            dayList={daysToCheck}
            form={facilityForm}
            onSubmit={async (formData) => {
              onSubmit();
              await editFacility(formData);
            }}
          />
        );
      },
    });
  };

  const handleDeleteConfirmation = (item: Facility) => {
    dialog({
      title: 'Delete Facility',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete ${item.name}?`}
            onConfirm={async () => {
              onSubmit();
              await handleFacilityDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleFacilityDelete = async (item: Facility) => {
    setLoading(true);
    try {
      const deletePromise = deleteFacilityTrigger(item.id).unwrap();
      await deletePromise;
    } catch (err) {
      toast({
        title: 'Facility Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected category. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: 'Facility deleted',
      description: "We've deleted the category you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchFacilities();
  };

  const addFacility = async (formData: FacilityForm) => {
    setLoading(true);
    try {
      const uploadedImages: FacilityImage[] = [];

      if (formData.images) {
        for (const image of formData.images) {
          const imageIndex = formData.images.findIndex(
            (x) => x.imageUrl === image.imageUrl
          );
          const uploadedImage = await uploadFile({
            file: image.imageUrl,
            fileName: `${formData.name}-${imageIndex}-${new Date().valueOf()}`,
          }).unwrap();

          uploadedImages.push({
            isMain: image.isMain,
            imageUrl: uploadedImage.url,
          });
        }
      }
      const savingFacility = formData as FacilityForm;
      await saveFacilityTriger({
        name: savingFacility.name,
        capacity: savingFacility.capacity,
        description: savingFacility.description,
        eventColor: savingFacility.eventColor,
        images: uploadedImages as FacilityFormImage[],
        lineItems: savingFacility.lineItems,
        start: savingFacility.start,
        end: savingFacility.end,
        roomType: savingFacility.roomType ?? undefined,
        ownerId: savingFacility.ownerId,
        availableDaysOfTheWeek: savingFacility.availableDaysOfTheWeek,
      }).unwrap();

      afterSaveChanges();
    } catch (err) {
      toast({
        title: 'Facility saving issue',
        description: "We've ran into an issue while saving the facility",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const editFacility = async (formData: FacilityForm) => {
    setLoading(true);
    try {
      const uploadedImages: FacilityImage[] = [];

      if (formData.images) {
        for (const image of formData.images) {
          if (image.rawImage) {
            const imageIndex = formData.images.findIndex(
              (x) => x.imageUrl === image.imageUrl
            );
            const uploadedImage = await uploadFile({
              file: image.imageUrl,
              fileName: `${
                formData.name
              }-${imageIndex}-${new Date().valueOf()}`,
            }).unwrap();

            uploadedImages.push({
              isMain: image.isMain,
              imageUrl: uploadedImage.url,
            });
          } else {
            uploadedImages.push({
              isMain: image.isMain,
              imageUrl: image.imageUrl,
            });
          }
        }
      }

      await editFacilityTrigger({
        id: formData.id ?? '',
        name: formData.name,
        description: formData.description,
        eventColor: formData.eventColor,
        images: uploadedImages,
        lineItems: formData.lineItems,
        capacity: formData.capacity,
        start: formData.start,
        end: formData.end,
        roomType: formData.roomType ?? undefined,
        ownerId: formData.ownerId,
        availableDaysOfTheWeek: formData.availableDaysOfTheWeek,
      }).unwrap();
      afterSaveChanges();
    } catch (err) {
      toast({
        title: 'Facility saving issue',
        description: "We've ran into an issue while saving the facility",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    setLoading(false);
  };

  const afterSaveChanges = () => {
    toast({
      title: 'Facility saved',
      description: "We've saved the facility you've added",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchFacilities();
  };

  const renderDefaultTableActions = (item: Facility) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              console.log('facility', item);
              handleEditDialog(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => handleDeleteConfirmation(item)}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };
  const tableColumns: ColumnDefinitionType<Facility>[] = [
    {
      key: 'images',
      header: 'Main Image',
      render: (item) => (
        <Image
          src={item.images?.find((x) => x.isMain === true)?.imageUrl}
          w={'80px'}
          h={'50px'}
          borderRadius={'5px'}
        />
      ),
    },
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'name',
      header: 'Facility Name',
      columnSearchable: true,
    },
    {
      key: 'roomType',
      header: 'Room Type',
      render: (item) => {
        return item.roomType === undefined ? (
          <AppText></AppText>
        ) : item.roomType === 0 ? (
          <AppText size={'xs'}>Meeting Room</AppText>
        ) : item.roomType === 1 ? (
          <AppText size={'xs'}>Klubhuis</AppText>
        ) : item.roomType === 2 ? (
          <AppText size={'xs'}>Barber</AppText>
        ) : (
          <></>
        );
      },
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  useEffect(() => {}, [facilities]);

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Box w={'100%'} display={'flex'} flexDir={'column'}>
        <Flex justifyContent={'space-between'} m={4}>
          <Text fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
            {'Facilities'}
          </Text>
          <Flex alignItems={'center'}>
            <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={handleAddDialog}
              size="sm"
              borderRadius={0}
              mr="4"
            >
              Add Facility
            </AppButton>
          </Flex>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={facilities?.data || []}
            noDataMessage={'No facilities added, start by adding some!'}
            columns={tableColumns}
            selectableRows={false}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={handleOnPageChange}
          ></AppPaginator>
        </Flex>
      </Box>
    </BaseLayout>
  );
};
export default Facilities;
