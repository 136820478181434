import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Input,
  Stack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AppButton,
  SelectListFilter,
  SelectOption,
  AppColors,
  AppText,
  BookingForm,
  Organization,
  OrganizationSearchResponse,
  OrganizationSelect,
  Booking,
  RoomType,
  Facility,
  AvailableBookingSlotResponse,
  useLoading,
  AvailableBookingFacility,
  Time,
  AppLoader,
} from '@giflo/shared';
import { BaseFormProps } from './base-forms';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBookingRoomType,
  selectDuration,
  setBookingDuration,
  setBookingEnd,
  setBookingOrganziation,
  setBookingRoomType,
  setBookingStart,
  setResetBookingTime,
  setSelectedBookingDate,
  setSelectedFacility,
} from '../../store/slices/booking.slice';
import { useFetchAvailableTimeSlotsMutation } from '../../store/api/organizationBookingApi';
import FacilitySearchForm from './booking-search-form';
import { addHours, addMinutes, format, setHours, setMinutes } from 'date-fns';
import { useAppSelector } from '../../store/store';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizations';

const bookingFormDefaultValues: BookingForm = {
  organizationId: '',
  facilityId: '',
  day: new Date(),
  start: undefined,
  end: undefined,
  timeSlot: undefined,
};

const bookingFormDataSchema = yup.object({
  organizationId: yup.string().required('Field is required'),
  facilityId: yup.string().required('Field is required'),
});

type BookingFormProps<T> = {
  form?: BookingForm;
  availableBookingFacility?: AvailableBookingFacility;
  organizationList?: OrganizationSelect[];
  deleteBookingItem?: (form: Booking) => void;
} & BaseFormProps<T>;

const BookingsForm: React.FC<BookingFormProps<BookingForm>> = ({
  form,
  onSubmit,
  deleteBookingItem,
}) => {
  const { setValue, getValues } = useForm<BookingForm>({
    defaultValues: form || bookingFormDefaultValues,
    resolver: yupResolver(bookingFormDataSchema),
    mode: 'onChange',
  });
  const roomTypeOptions: SelectOption[] = [
    {
      label: 'Barber',
      value: RoomType.BARBER,
    },
    {
      label: 'Meeting Room',
      value: RoomType.MEETING_ROOM,
    },
    {
      label: 'Club House',
      value: RoomType.CLUB_HOUSE,
    },
  ];
  const dispatch = useDispatch();
  const { setLoading, loading } = useLoading();

  const bookingDuration = useAppSelector(selectDuration);
  const bookingRoomType = useAppSelector(selectBookingRoomType);
  const [fetchAvailableSlots] = useFetchAvailableTimeSlotsMutation();

  const { data: organizationOptions } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });

  const [organizationSearchResponse, setOrganizationSearchResponse] = useState<
    OrganizationSearchResponse[] | undefined
  >([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrganizationSearchResponse | undefined
  >();
  const [desktopResultsVisibility, setDesktopResultsVisibility] =
    useState<string>('none');
  const [availableSlots, setAvailableSlots] = useState<SelectOption[]>([]);

  const [currentOrganization, setCurrentOrgnization] = useState<Organization>();

  const [searchParam, setSearchParam] = useState<string>('');
  const currentRoomType = useSelector(selectBookingRoomType);
  const [availableBookingSlots, setAvailableBookingSlots] =
    useState<AvailableBookingSlotResponse>();

  const [currentSelectedFacility, setCurrentSelectedFacility] =
    useState<Facility>();

  const postSearch = async (name: string) => {
    if (!name) return setOrganizationSearchResponse(organizationOptions);

    const lowerCaseSearchStr = name.toLowerCase();
    if (!organizationOptions) return;
    const foundItems = organizationOptions.filter((organization) => {
      return organization.name.toLowerCase().includes(lowerCaseSearchStr);
    });
    setOrganizationSearchResponse(foundItems);
  };

  // const changeOrganization = (organizationId: string, isSelected: boolean) => {
  //   const organizationCopy: OrganizationSelect[] = JSON.parse(
  //     JSON.stringify(organizations)
  //   );
  //   const organizationIndex = organizationCopy.findIndex(
  //     (x) => x.id === organizationId
  //   );
  //   organizationCopy[organizationIndex].selected = isSelected;
  //   setOrganizations(organizationCopy);
  // };

  const handleFacilitySearchForm = async (
    type: RoomType,
    date: Date,
    duration: number
  ) => {
    setAvailableBookingSlots(undefined);

    if (date) {
      setLoading(true);
      const data = await fetchAvailableSlots({
        organizationId: selectedOrganization?.id ?? '',
        capacity: 1,
        day: format(new Date(date), 'yyyyMMdd'),
        duration: duration ?? 15,
        roomType: type,
      }).unwrap();

      setAvailableBookingSlots(data);
      setLoading(false);
    }
  };

  const selectOrganizationResponse = (
    organizationSearchResponse: OrganizationSearchResponse
  ) => {
    setCurrentOrgnization(undefined);
    setSelectedOrganization(organizationSearchResponse);
    setOrganizationSearchResponse(undefined);
  };

  const handleFormSubmit = () => {
    const formValues = getValues();
    setValue('facilityId', formValues.facilityId);
    return onSubmit(formValues);
  };

  const handleRoomTypeSelect = (item: SelectOption) => {
    dispatch(setBookingRoomType(item.value));
  };

  const getFacilitySlots = (item: AvailableBookingFacility) => {
    const selectors: SelectOption[] = [];
    item.slots.map((x) => {
      selectors.push({
        label: `${x.start.hour}:${x.start.minute}`,
        value: {
          start: {
            hour: x.start.hour,
            minute: x.start.minute,
          },
        },
      });
    });
    setAvailableSlots(selectors);
  };

  return (
    <Stack spacing={4}>
      {loading && <AppLoader />}
      <Flex direction={'column'} gap={6}>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormLabel>Search Organizations </FormLabel>
          <Flex
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'end'}
          >
            <Input
              w={'full'}
              placeholder="Type here"
              onChange={(e) => {
                e.preventDefault();
                setSearchParam(e.currentTarget.value);
              }}
            />
            <AppButton
              ml={'2'}
              borderRadius={0}
              bgColor={AppColors.primary}
              color={'white'}
              onClick={() => {
                postSearch(searchParam as string);
              }}
            >
              Search
            </AppButton>
          </Flex>
        </form>
        {organizationSearchResponse &&
          organizationSearchResponse.length > 0 && (
            <Box maxH={'200px'} overflowY={'scroll'}>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {organizationSearchResponse.map(
                      (selectedOrganization, index) => {
                        return (
                          <Tr key={`${selectedOrganization.name}-${index}`}>
                            <Td>
                              <AppText>{selectedOrganization.name}</AppText>
                            </Td>
                            <Td>
                              <Flex>
                                <AppButton
                                  mr={4}
                                  bgColor={AppColors.secondary}
                                  color={'white'}
                                  variant={'solid'}
                                  size="xs"
                                  borderRadius="full"
                                  onClick={() => {
                                    selectOrganizationResponse(
                                      selectedOrganization
                                    );
                                    setValue(
                                      'organizationId',
                                      selectedOrganization!.id
                                    );
                                    dispatch(
                                      setBookingOrganziation(
                                        selectedOrganization.id
                                      )
                                    );
                                  }}
                                >
                                  Select
                                </AppButton>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}

        {selectedOrganization && (
          <Flex justifyContent={'start'} alignItems={'center'}>
            <AppText isTruncated>Selected Organization:</AppText>

            <Tag borderRadius="full" colorScheme={'gray'} ml={'4'}>
              <TagLabel>{selectedOrganization.name}</TagLabel>
            </Tag>
          </Flex>
        )}
        {currentOrganization && (
          <Flex justifyContent={'start'} alignItems={'center'}>
            <AppText isTruncated>Selected Organization:</AppText>

            <Tag borderRadius="full" colorScheme={'gray'} ml={'4'}>
              <TagLabel>{currentOrganization?.name}</TagLabel>
            </Tag>
          </Flex>
        )}

        {/* <Box>
          {organizations?.map((organization) => (
            <Box key={organization.id}>
              <Checkbox
                colorScheme={'gray'}
                key={organization.id}
                isChecked={organization.selected}
                onChange={(e) =>
                  changeOrganization(organization.id, e.target.checked)
                }
              >
                {organization.name}
              </Checkbox>
            </Box>
          ))}
        </Box> */}
      </Flex>
      <FormLabel m={0}>Room Type</FormLabel>
      <SelectListFilter
        name="roomTypes"
        w={'full'}
        options={roomTypeOptions}
        isInModal
        isMulti={false}
        placeholder="Room Type"
        onSelectionChange={(item: SelectOption[]) => {
          handleRoomTypeSelect(item[0]);
        }}
        showClear={false}
      />
      <Flex direction={'column'} gap={3} w={'full'}>
        <FacilitySearchForm
          onSubmit={async (formData) => {
            dispatch(setResetBookingTime());
            dispatch(setBookingDuration(formData.duration ?? 15));
            handleFacilitySearchForm(
              currentRoomType!,
              formData.date ?? new Date(),
              formData.duration ?? 15
            );
            setDesktopResultsVisibility('block');
            dispatch(
              setSelectedBookingDate(
                formData.date?.valueOf() ?? new Date().valueOf()
              )
            );
          }}
          handleFirstAvailableSlot={() => {}}
        />
        <Box display={desktopResultsVisibility}>
          {!currentSelectedFacility && (
            <Grid
              h={'200px'}
              overflow={'scroll'}
              gridTemplateColumns={'repeat(1, 1fr)'}
              gridColumnGap={3}
              gridRowGap={3}
            >
              {availableBookingSlots?.availableBookingFacilities?.map((x) => {
                return (
                  <GridItem
                    colSpan={1}
                    h={'auto'}
                    key={x.facility.id}
                    flexDir={'column'}
                    p={4}
                  >
                    <Flex w={'full'} justify={'space-between'} align={'center'}>
                      <Flex gap={3} align={'center'}>
                        <Image src={x.facility.images[0].imageUrl} h={'60px'} />
                        <AppText>{x.facility.name}</AppText>
                      </Flex>
                      <Flex gap={3} align={'center'}>
                        <AppButton
                          bgColor={AppColors.tertiary}
                          color={'white'}
                          borderRadius={0}
                          onClick={() => {
                            dispatch(setSelectedFacility(x.facility));
                            setSelectedFacility(x.facility);
                            setCurrentSelectedFacility(x.facility);
                            setValue('facilityId', x.facility.id);
                            getFacilitySlots(x);
                          }}
                        >
                          Select
                        </AppButton>
                      </Flex>
                    </Flex>
                  </GridItem>
                );
              })}
            </Grid>
          )}
          {currentSelectedFacility && (
            <Flex mt={4} justifyContent={'start'} alignItems={'center'}>
              <AppText isTruncated>Selected Facility:</AppText>

              <Tag borderRadius="full" colorScheme={'gray'} ml={'4'}>
                <TagLabel>{currentSelectedFacility.name}</TagLabel>
              </Tag>
            </Flex>
          )}
        </Box>
      </Flex>
      <FormLabel>Select Slot</FormLabel>
      <Flex w={'full'} gap={3}>
        <SelectListFilter
          w={'full'}
          name="duration"
          options={availableSlots}
          isInModal
          isMulti={false}
          placeholder="Slot"
          onSelectionChange={(item: SelectOption[]) => {
            const startTime: Time = item[0].value.start;
            dispatch(setBookingStart(startTime));

            const duration =
              bookingRoomType === RoomType.BARBER ? 30 : bookingDuration;
            let date = setHours(new Date(), startTime?.hour ?? 0);
            date = setMinutes(date, startTime?.minute ?? 0);

            if (duration && duration > 10) {
              date = addMinutes(date, duration);
            } else {
              date = addHours(date, duration ?? 0);
            }

            const endTime: Time = {
              hour: date.getHours(),
              minute: date.getMinutes(),
            };
            dispatch(setBookingEnd(endTime!));

            setValue('start.hour', startTime.hour);
            setValue('start.minute', startTime.minute);
            setValue('end.hour', endTime.hour);
            setValue('end.minute', endTime.minute);
          }}
          showClear={false}
        />
      </Flex>
      <Flex direction={'column'} gap={4}>
        <AppButton
          bgColor={AppColors.primary}
          color={'white'}
          onClick={() => handleFormSubmit()}
        >
          Save
        </AppButton>
        {/* THIS WILL BE MOVED TO THE VIEW BOOKING COMPONENT */}
        {/* <AppButton
          bgColor={AppColors.secondary}
          color={'white'}
          onClick={() => {
            deleteBookingItem && deleteBookingItem(form as Booking);
          }}
          visibility={form?.id ? 'visible' : 'hidden'}
        >
          Delete
        </AppButton> */}
      </Flex>
    </Stack>
  );
};

export default BookingsForm;
