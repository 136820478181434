import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../pages/login/login';
import ProtectedRoute from './protected-route';
import { AuthOutlet, RouterOutlet } from './router-outlet';
import UserProfile from '../pages/userProfile/userProfile';
import Dashboard from '../pages/dashboard/dashboard';
import { UserManagementPage } from '../pages/users/user-management';
import Facilities from '../pages/facilities/facilities';
import Organizations from '../pages/organizations/organizations';
import ContactsPage from '../pages/contacts/contacts-page';
import ActivityPage from '../pages/activity/activity';
import BookingsCalendar from '../pages/bookings-calendar/bookings-calendar';
import Reports from '../pages/reports/reports';

export enum RoutesList {
  Index = '/',
  Signin = '/signin',
  Dashboard = '/dashboard',
  Users = '/user-management',
  Roles = '/role-management',
  MasterDataErrorHandling = '/master-data/error-handling-mapping',
  MasterDataOrderingProductMatrix = '/master-data/online-ordering-product-matrix',
  MasterDataBusinessReporting = '/master-data/business-reporting-mapping',
  Reports = '/report-management',
  UserProfile = '/user-profile',
  Facilities = '/facilities',
  Organizations = '/organizations',
  Contacts = '/contacts',
  Activity = '/activity',
  Bookings = '/bookings',
}

const Router: React.FC = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path="/" element={<AuthOutlet />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="/" element={<RouterOutlet />}>
          <Route
            path="dashboard"
            element={
              <ProtectedRoute route={RoutesList.Dashboard}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="authentication/login-callback"
            element={
              <ProtectedRoute route={RoutesList.Dashboard}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-profile"
            element={
              <ProtectedRoute route={RoutesList.UserProfile}>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="user-management"
            element={
              <ProtectedRoute route={RoutesList.Users}>
                <UserManagementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="facilities"
            element={
              <ProtectedRoute route={RoutesList.Facilities}>
                <Facilities />
              </ProtectedRoute>
            }
          />
          <Route
            path="organizations"
            element={
              <ProtectedRoute route={RoutesList.Organizations}>
                <Organizations />
              </ProtectedRoute>
            }
          />
          <Route
            path="contacts"
            element={
              <ProtectedRoute route={RoutesList.Contacts}>
                <ContactsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="activity"
            element={
              <ProtectedRoute route={RoutesList.Activity}>
                <ActivityPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="bookings"
            element={
              <ProtectedRoute route={RoutesList.Bookings}>
                <BookingsCalendar />
              </ProtectedRoute>
            }
          />
          <Route
            path="report-management"
            element={
              <ProtectedRoute route={RoutesList.Reports}>
                <Reports />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
