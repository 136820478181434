import BaseLayout from '../../components/layouts/base-layout';
import {
  Box,
  Text,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  DeleteIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import { useEffect, useState } from 'react';
import {
  AppConfirm,
  AppLoader,
  User,
  UserUpsertRequest,
  useDialog,
} from '@giflo/shared';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useFetchUsersByOptionsQuery,
  useUserAccountInviteByUserIdMutation,
} from '../../store/api/userApi';
import UsersForm, { UserFormData } from '../../components/forms/user-form';
import {
  AppButton,
  AppColors,
  AppDataTable,
  AppPaginator,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  useDataTable,
  usePanel,
  useLoading,
} from '@giflo/shared';
import OrganizationNameCell from '../../components/table-cells/organization-name-cell/organization-name-cell';
import UserRoleCell from '../../components/table-cells/user-role-cell/user-role-cell';

export const UserManagementPage: React.FC = () => {
  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
    handleSearchChange,
  } = useDataTable<User>();

  const {
    data: users,
    isLoading,
    refetch: refetchUsers,
  } = useFetchUsersByOptionsQuery(
    {
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (users?.pagination) {
      setPagination(users?.pagination);
    }
  }, [users]);

  const [saveUserTrigger] = useCreateUserMutation();
  const [editUserTrigger] = useUpdateUserMutation();
  const [deleteUserTrigger] = useDeleteUserMutation();
  const [sendInviteToUserTrigger] = useUserAccountInviteByUserIdMutation();
  const { setLoading } = useLoading();
  const panel = usePanel();
  const dialog = useDialog();
  const toast = useToast();

  const renderDefaultTableActions = (item: User) => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          bgColor={'white'}
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<CheckIcon />}
            onClick={() => handleSendConfirmation(item)}
          >
            Send Invite via email
          </MenuItem>
          <MenuItem
            icon={<SettingsIcon />}
            onClick={() => {
              handleEditDialog(item);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              handleDeleteConfirmation(item);
            }}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const userColumns: ColumnDefinitionType<User>[] = [
    {
      type: ColumnSpecialTypes.date,
      key: 'dateCreated',
      header: 'Date Added',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'isInvited',
      header: 'Invited',
      render: (item) => (
        <>
          {item?.isInvited === true ? (
            <CheckIcon color={'green'} w={'12px'} h={'12px'} />
          ) : (
            <CloseIcon color={'red'} w={'12px'} h={'12px'} />
          )}
        </>
      ),
    },
    {
      key: 'organizationId',
      header: 'Organization',
      headerSortable: false,
      columnSearchable: true,
      render: (item) => (
        <OrganizationNameCell name={item?.organization?.name ?? ''} />
      ),
    },
    {
      key: 'roleId',
      header: 'Role',
      headerSortable: false,
      columnSearchable: false,
      render: (item) => <UserRoleCell item={item.roleId ?? 1} />,
    },
    {
      key: 'firstName',
      header: 'Name',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'lastName',
      header: 'Last Name',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'email',
      header: 'Email',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'custom',
      headerSortable: false,
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  const [tableColumns, setTableColumns] =
    useState<ColumnDefinitionType<User>[]>(userColumns);

  const handleAddPanel = () => {
    panel({
      title: 'Add User',
      render: (onSubmit) => {
        return (
          <UsersForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          />
        );
      },
    });
  };
  const handleEditDialog = (user: User) => {
    const editForm: UserFormData = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: '',
      organizationId: user.organizationId,
      organization: user.organization,
      roleId: user.roleId,
      isFirstLogin: user.isFirstLogin,
    };
    panel({
      title: 'Edit User',
      render: (onSubmit) => {
        return (
          <UsersForm
            form={editForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertUser(formData);
            }}
          />
        );
      },
    });
  };

  const handleDeleteConfirmation = (item: User) => {
    dialog({
      title: 'Delete User',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete ${item.firstName}?`}
            onConfirm={async () => {
              onSubmit();
              await handleUserDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleUserDelete = async (item: User) => {
    setLoading(true);
    try {
      await deleteUserTrigger(item.id).unwrap();
    } catch (err) {
      toast({
        title: 'User Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected user. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: 'User deleted',
      description: "We've deleted the user you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchUsers();
    setLoading(false);
  };

  const handleSendConfirmation = (item: User) => {
    dialog({
      title: 'Send Invite via email',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to send an invite to user ${item.firstName}? This will force the user to reset their password.`}
            onConfirm={async () => {
              onSubmit();
              await handleSend(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleSend = async (item: User) => {
    setLoading(true);
    try {
      await sendInviteToUserTrigger(item.id).unwrap();
      toast({
        title: 'User Invite sent',
        description: "We've sent an invite email to the user you selected",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'User Invite Error',
        status: 'error',
        description:
          "We've run into a problem sending an invite to the selected user. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    refetchUsers();
    setLoading(false);
  };

  const upsertUser = async (formData: UserUpsertRequest) => {
    setLoading(true);
    let eddittedUser = formData.id ? true : false;
    try {
      if (!eddittedUser) {
        await saveUserTrigger({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          organizationId: formData.organizationId,
          roleId: formData.roleId,
          isFirstLogin: false,
        }).unwrap();
      } else {
        await editUserTrigger({
          id: formData.id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          organizationId: formData.organizationId,
          roleId: formData.roleId,
          isFirstLogin: formData.isFirstLogin,
        }).unwrap();
        eddittedUser = true;
      }

      toast({
        title: `User ${eddittedUser ? 'updated' : 'saved'}`,
        description: `We've ${eddittedUser ? 'updated' : 'saved'} the user`,
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      refetchUsers();
    } catch (e) {
      toast({
        title: 'User saving issue',
        description: `We've run into a problem saving the user, ${e}. Contact support for help`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    setLoading(false);
    refetchUsers();
  };

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Box w={'100%'} display={'flex'} flexDir={'column'}>
        <Flex justifyContent={'space-between'} m={4} align={'center'}>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="10px"
            mb="4px"
          >
            {'Users'}
          </Text>
          <Flex alignItems={'center'}>
            <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={() => handleAddPanel()}
              size="sm"
              borderRadius={0}
              mr="4"
            >
              Add User
            </AppButton>
          </Flex>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          m={4}
          flexDir={'column'}
          p={4}
        >
          <AppDataTable
            data={users?.data || []}
            noDataMessage={'No users added, start by adding some!'}
            columns={tableColumns}
            selectableRows={false}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={handleOnPageChange}
          ></AppPaginator>
        </Flex>
      </Box>
    </BaseLayout>
  );
};
