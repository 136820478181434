import {
  Flex,
  Menu,
  Button,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import {
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  AppColors,
  AppText,
  AppButton,
  useLoading,
  usePanel,
  useDataTable,
  AppPaginator,
  Activity,
  ActivityEditForm,
  ActivityForm,
  AppDatePicker,
  AppLoader,
} from '@giflo/shared';
import BaseLayout from '../../components/layouts/base-layout';
import {
  useCreateActivityMutation,
  useDeleteActivityMutation,
  useUpdateActivityMutation,
  useFetchActivitiesByOptionsQuery,
  useSendActivityMutation,
} from '../../store/api/activityApi';
import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  DeleteIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import ActivitiesForm from '../../components/forms/activity-form';
import { useEffect } from 'react';
import ActivitySendForm from '../../components/forms/activity-send-form';

export const ActivityPage: React.FC = () => {
  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
    handleSearchChange,
  } = useDataTable<Activity>();

  const {
    data: allActivities,
    isLoading,
    refetch: refetchActivities,
  } = useFetchActivitiesByOptionsQuery(
    {
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (allActivities?.pagination) {
      setPagination(allActivities?.pagination);
    }
  }, [allActivities]);

  const { setLoading } = useLoading();
  const [saveActivity] = useCreateActivityMutation();
  const [updateActivity] = useUpdateActivityMutation();
  const [deleteActivity] = useDeleteActivityMutation();
  const [sendActivity] = useSendActivityMutation();

  const toast = useToast();
  const panel = usePanel();

  const upsertActivity = async (formData: any) => {
    let eddittedActivity = formData.id ? true : false;
    setLoading(true);
    try {
      if (!eddittedActivity) {
        const createActivity = formData as ActivityForm;
        await saveActivity({
          title: createActivity.title,
          description: createActivity.description,
        }).unwrap();
      } else {
        const editActivity = formData as ActivityEditForm;
        await updateActivity({
          id: editActivity.id ?? '',
          title: editActivity.title,
          description: editActivity.description,
        });
      }
    } catch (e) {
      toast({
        title: 'Activity saving error',
        description: "We've run into an error saving the activity you created.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: 'Activity saved',
      description: "We've saved the activity you created",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchActivities();
  };

  const handleAdd = () => {
    panel({
      title: 'Add Activity',
      render: (onSubmit) => {
        return (
          <ActivitiesForm
            onSubmit={async (formData) => {
              console.log('activity', formData);
              onSubmit();
              await upsertActivity(formData);
            }}
          />
        );
      },
    });
  };

  const handleEdit = (item: Activity) => {
    const activityForm: ActivityEditForm = {
      id: item.id ?? '',
      title: item.title,
      description: item.description,
      isSent: item.isSent,
    };
    panel({
      title: 'Edit Activity',
      render: (onSubmit) => {
        return (
          <ActivitiesForm
            form={activityForm}
            onSubmit={async (formData) => {
              console.log('edit activity', formData);
              onSubmit();
              await upsertActivity(formData);
            }}
          />
        );
      },
    });
  };

  const handleDelete = async (item: Activity) => {
    setLoading(true);
    try {
      await deleteActivity(item.id).unwrap();
    } catch (e) {
      toast({
        title: 'Activity Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected activity. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: 'Activity Deleted',
      description: "We've deleted the activity you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchActivities();
  };

  const handleActivitySend = (item: Activity) => {
    const activityForm: ActivityEditForm = {
      id: item.id ?? '',
      title: item.title,
      description: item.description,
      isSent: item.isSent,
    };

    panel({
      title: 'Send Activity',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <ActivitySendForm
            form={activityForm}
            onSubmit={async (formData) => {
              onSubmit();
              setLoading(true);
              try {
                const itemCopy: Activity = JSON.parse(JSON.stringify(item));
                itemCopy.dateExpired = formData.dateExpired;
                await sendActivity(itemCopy);
              } catch (e) {
                toast({
                  title: 'Activity Send Error',
                  status: 'error',
                  description:
                    "We've run into a problem sending the selected activity. Contact support for help",
                  duration: 9000,
                  isClosable: true,
                });
                setLoading(false);
              }
              toast({
                title: 'Activity Sent!',
                description: "We've sent the activity you selected",
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
              setLoading(false);
              refetchActivities();
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: Activity) => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          bgColor={'white'}
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<CheckIcon />}
            onClick={() => handleActivitySend(item)}
          >
            {item.isSent === true ? 'Resend' : 'Send'}
          </MenuItem>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEdit(item)}>
            Edit
          </MenuItem>
          <MenuItem icon={<DeleteIcon />} onClick={() => handleDelete(item)}>
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const activityColumns: ColumnDefinitionType<Activity>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'Date Added',
      headerSortable: true,
    },
    {
      key: 'title',
      header: 'Activity',
      columnSearchable: true,
    },
    {
      key: 'isSent',
      header: 'Sent',
      render: (item) => (
        <>
          {item?.isSent === true ? (
            <CheckIcon color={'green'} w={'12px'} h={'12px'} />
          ) : (
            <CloseIcon color={'red'} w={'12px'} h={'12px'} />
          )}
        </>
      ),
    },
    {
      key: 'dateExpired',
      type: ColumnSpecialTypes.date,
      header: 'Date Expiring',
      headerSortable: true,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];
  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Flex direction={'column'} w={'full'}>
        <Flex justify={'space-between'} m={4} align={'center'}>
          <AppText fontWeight={'bold'} fontSize={'2xl'} mt="10px" mb="4px">
            Activities
          </AppText>
          <AppButton
            bgColor={AppColors.tertiary}
            color={'white'}
            borderRadius={0}
            size={'sm'}
            onClick={handleAdd}
          >
            New Activity
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            columns={activityColumns || []}
            data={allActivities?.data || []}
            noDataMessage="No activities added yet, start by adding some!"
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={handleOnPageChange}
          ></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default ActivityPage;
