import {
  ColumnDefinitionType,
  TableSearchEventProps,
} from '../components/app-data-table/app-data-table-types';
import { SearchParam, SortParam } from '../models/base';

export const searchingColumnsToSearchParam = <T extends {}>(
  event: TableSearchEventProps<T>
) => {
  let SearchParam: SearchParam = {
    value: event.search,
    columnNames: event.selectedColumns.map((x) => x.key),
  };

  return SearchParam;
};

export const sortingColumnsToSortingArray = <T extends {}>(
  columns: ColumnDefinitionType<T>[]
) => {
  let sortingArray: SortParam[] = [];

  columns.forEach((col) => {
    if (col.headerSortDir) {
      sortingArray.push({
        key: col.key.toString(),
        value: col.headerSortDir,
      } as SortParam);
    }
  });

  return sortingArray;
};
