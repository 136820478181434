import React from 'react';
import { Flex, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ActivityForm,
  AppButton,
  AppColors,
  AppDatePicker,
  AppText,
} from '@giflo/shared';
import { BaseFormProps } from './base-forms';
import AppTextRenderer from '@giflo/shared/lib/components/app-text-renderer/app-text-renderer';

const activityFormDefaultValues: ActivityForm = {
  title: '',
  description: '',
  dateExpired: undefined,
  isSent: false,
};

const activityFormDataSchema = yup.object({
  title: yup.string().required('Field is required'),
  description: yup.string().required('Field is required'),
  dateExpired: yup.date().required('Field is required'),
});

type ActivityFormProps<T> = {
  form?: ActivityForm;
} & BaseFormProps<T>;

const ActivitySendForm: React.FC<ActivityFormProps<ActivityForm>> = ({
  isLoading,
  form,
  onSubmit,
}) => {
  const {
    control: control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<ActivityForm>({
    defaultValues: form || activityFormDefaultValues,
    resolver: yupResolver(activityFormDataSchema),
    mode: 'onChange',
  });

  return (
    <Stack spacing={4}>
      <Flex direction={'column'} gap={8}>
        <AppText>{form?.title}</AppText>
        <AppTextRenderer markdown={form?.description ?? ''} />
      </Flex>

      <AppDatePicker<ActivityForm>
        name="dateExpired"
        control={control}
        error={undefined}
        label="Date Expiring"
      />
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        onClick={handleSubmit(onSubmit)}
        isDisabled={!isValid}
        mt={12}
      >
        Send
      </AppButton>
    </Stack>
  );
};

export default ActivitySendForm;
