import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Input,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { UserRole } from '@giflo/shared';
import { useForm } from 'react-hook-form';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizations';
import {
  AppInput,
  AppButton,
  SelectListFilter,
  SelectOption,
  AppColors,
  AppText,
  Organization,
  OrganizationSearchResponse,
  OrganizationSelect,
} from '@giflo/shared';
import { BaseFormProps } from './base-forms';

export interface UserFormData {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  organizationId?: string;
  organization?: Organization;
  roleId?: UserRole;
  isFirstLogin: boolean;
}

export const UserSearchDefaultValues: UserFormData = {
  id: undefined,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  organizationId: undefined,
  organization: undefined,
  roleId: undefined,
  isFirstLogin: true,
};

type UserFormProps<T> = {
  form?: UserFormData;
} & BaseFormProps<T>;

const UsersForm: React.FC<UserFormProps<UserFormData>> = ({
  form,
  onSubmit,
}) => {
  const [userRoleSelectors, setUserRoleSelectors] = useState<SelectOption[]>();

  const { data: userOrganizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });

  // const userRoles = Object.values(UserRole).filter((x) => {
  //   return isNaN(Number(x));
  // });

  const {
    control: UserControl,
    formState: { isValid, errors },
    setValue,
    handleSubmit,
  } = useForm<UserFormData>({
    defaultValues: form || UserSearchDefaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    const roleSelectors: SelectOption[] = [
      { label: 'Admin', value: UserRole.ADMIN },
      { label: 'User', value: UserRole.USER },
    ];

    setUserRoleSelectors(roleSelectors);
  }, []);

  const [organizations, setOrganizations] = useState<OrganizationSelect[]>([]);
  const [organizationSearchResponse, setOrganizationSearchResponse] = useState<
    OrganizationSearchResponse[] | undefined
  >([]);
  const [selectedOrganization, setSelectedOrganization] = useState<
    OrganizationSearchResponse | undefined
  >();
  const [currentOrganization, setCurrentOrgnization] = useState<Organization>();

  const [searchParam, setSearchParam] = useState<string>('');

  const [currentRole, setCurrentRole] = useState<SelectOption>();

  const postSearch = (searchString: string) => {
    if (!searchString) return setOrganizationSearchResponse(userOrganizations);

    const lowerCaseSearchStr = searchString.toLowerCase();

    if (!userOrganizations) return;

    const foundItems = userOrganizations.filter((userOrganization) => {
      return userOrganization.name.toLowerCase().includes(lowerCaseSearchStr);
    });

    setOrganizationSearchResponse(foundItems);
  };

  const selectOrganizationResponse = (
    organizationSearchResponse: OrganizationSearchResponse
  ) => {
    setCurrentOrgnization(undefined);
    setSelectedOrganization(organizationSearchResponse);
    setOrganizationSearchResponse(undefined);
  };

  const changeOrganization = (organizationId: string, isSelected: boolean) => {
    const organizationCopy: OrganizationSelect[] = JSON.parse(
      JSON.stringify(organizations)
    );
    const organizationIndex = organizationCopy.findIndex(
      (x) => x.id === organizationId
    );
    organizationCopy[organizationIndex].selected = isSelected;
    setOrganizations(organizationCopy);
  };

  useEffect(() => {
    if (form && form.id && form.organization) {
      const userRole = userRoleSelectors?.filter((x) => {
        return x.value === form.roleId
      })
      setCurrentOrgnization(form.organization);
      if (userRole) {
        setCurrentRole(userRole[0])
      }
    }
  }, [form, userRoleSelectors]);

  return (
    <Flex direction={'column'} gap={6}>
      <Flex direction={'column'} gap={6}>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <FormLabel>Search Organisations </FormLabel>
          <Flex
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'end'}
          >
            <Input
              w={'full'}
              placeholder='Type here'
              onChange={(e) => {
                e.preventDefault();
                setSearchParam(e.currentTarget.value);
              }}
            />
            <AppButton
              ml={'2'}
              bgColor={AppColors.primary}
              color={'white'}
              onClick={() => {
                postSearch(searchParam as string);
              }}
            >
              Search
            </AppButton>
          </Flex>
        </form>
        {organizationSearchResponse &&
          organizationSearchResponse.length > 0 && (
            <Box maxH={'200px'} overflowY={'scroll'}>
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {organizationSearchResponse.map(
                      (selectedOrganization, index) => {
                        return (
                          <Tr key={`${selectedOrganization.name}-${index}`}>
                            <Td>
                              <AppText>{selectedOrganization.name}</AppText>
                            </Td>
                            <Td>
                              <Flex>
                                <AppButton
                                  mr={4}
                                  bgColor={AppColors.secondary}
                                  color={'white'}
                                  variant={'solid'}
                                  size='xs'
                                  borderRadius='full'
                                  onClick={() => {
                                    selectOrganizationResponse(
                                      selectedOrganization
                                    );
                                    setValue(
                                      'organizationId',
                                      selectedOrganization!.id
                                    );
                                  }}
                                >
                                  Select
                                </AppButton>
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}

        {selectedOrganization && (
          <Flex justifyContent={'start'} alignItems={'center'}>
            <AppText isTruncated>Selected Organization:</AppText>

            <Tag borderRadius='full' colorScheme={'gray'} ml={'4'}>
              <TagLabel>{selectedOrganization.name}</TagLabel>
            </Tag>
          </Flex>
        )}

        {currentOrganization && (
          <Flex justifyContent={'start'} alignItems={'center'}>
            <AppText isTruncated>Selected Organization:</AppText>

            <Tag borderRadius='full' colorScheme={'gray'} ml={'4'}>
              <TagLabel>{currentOrganization?.name}</TagLabel>
            </Tag>
          </Flex>
        )}

        <Box>
          {organizations?.map((organization) => (
            <Box key={organization.id}>
              <Checkbox
                colorScheme={'gray'}
                key={organization.id}
                isChecked={organization.selected}
                onChange={(e) =>
                  changeOrganization(organization.id, e.target.checked)
                }
              >
                {organization.name}
              </Checkbox>
            </Box>
          ))}
        </Box>
      </Flex>
      <AppInput<UserFormData>
        control={UserControl}
        error={errors.firstName}
        name='firstName'
        label='First Name'
      />
      <AppInput<UserFormData>
        control={UserControl}
        error={errors.lastName}
        name='lastName'
        label='Last Name'
      />
      <AppInput<UserFormData>
        control={UserControl}
        error={errors.email}
        name='email'
        label='Email'
      />
      <AppInput<UserFormData>
        control={UserControl}
        error={errors.password}
        name='password'
        label='Password:'
      />
      <SelectListFilter
        options={userRoleSelectors || []}
        defaultOption={currentRole}
        isInModal
        isMulti={false}
        placeholder='Select User Role'
        onSelectionChange={(item: SelectOption[]) => {
          setValue('roleId', item[0].value)
        }}
      />
      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        isDisabled={!isValid}
        onClick={handleSubmit(onSubmit)}
      >
        Save
      </AppButton>
    </Flex>
  );
};

export default UsersForm;
