import { Box } from '@chakra-ui/react';
import * as DOMPurify from 'dompurify';

type TextRenderProps = {
  markdown: string;
};

const AppTextRenderer: React.FC<TextRenderProps> = ({ markdown }) => {
  const safeMarkdown = DOMPurify.sanitize(markdown);
  return <Box dangerouslySetInnerHTML={{ __html: safeMarkdown }}></Box>;
};

export default AppTextRenderer;
