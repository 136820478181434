import React from 'react';
import { Flex } from '@chakra-ui/react';
import {
  AppText,
  BookingForm,
  AppCarousel,
  AppButton,
  AppColors,
} from '@giflo/shared';
import AppTextRenderer from '@giflo/shared/lib/components/app-text-renderer/app-text-renderer';
import { useSelector } from 'react-redux';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { format } from 'date-fns';
import {
  selectBookingDay,
  selectedEndTime,
  selectedFacility,
  selectedStartTime,
} from '../../store/slices/booking.slice';

export type BookingConfirmProps = {
  booking: BookingForm;
  handleSubmit: () => void;
};

const BookingConfirmation: React.FC<BookingConfirmProps> = ({
  booking,
  handleSubmit,
}) => {
  const startTime = useSelector(selectedStartTime);
  const endTime = useSelector(selectedEndTime);
  const facility = useSelector(selectedFacility);
  const bookingDate = useSelector(selectBookingDay);

  return (
    <Flex gap={4} direction={'column'} w={'full'}>
      <AppCarousel facility={facility!} />
      <AppText fontSize={'2xl'} fontWeight={'600'}>
        {facility?.name}
      </AppText>
      <AppTextRenderer markdown={facility?.description!} />
      <Flex
        direction={'column'}
        gap={3}
        w={'full'}
        p={4}
        bgColor={AppColors.primary}
        color={'white'}
      >
        <AppText fontSize={'2xl'} fontWeight={'600'}>
          Booking Details
        </AppText>
        <Flex w={'full'} justify={'space-between'}>
          <AppText>{format(new Date(bookingDate!), 'dd MMM yyyy')}</AppText>
          <Flex alignItems={'center'}>
            <AiOutlineClockCircle />
            <AppText ml={2} fontWeight={'bold'}>
              {`${startTime?.hour}:${
                startTime?.minute === 0 ? '00' : startTime?.minute
              } - ${endTime?.hour}:${
                endTime?.minute === 0 ? '00' : endTime?.minute
              }`}
            </AppText>
          </Flex>
        </Flex>
      </Flex>
      <AppButton
        bgColor={AppColors.tertiary}
        color={'white'}
        borderRadius={0}
        onClick={() => {
          handleSubmit();
        }}
      >
        Confirm Booking
      </AppButton>
    </Flex>
  );
};

export default BookingConfirmation;
