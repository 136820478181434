import { Base } from './base';
import { Time } from './time';

export type FacilityImage = {
  imageUrl: string;
  isMain: boolean;
};

export type FacilityFormImage = {
  imageUrl: string;
  rawImage?: string;
  isMain: boolean;
};

export type LineItem = {
  lineItem: string;
};

export enum RoomType {
  MEETING_ROOM,
  CLUB_HOUSE,
  BARBER,
}

export interface Facility extends Base {
  name: string;
  description: string;
  eventColor: string;
  capacity?: number;
  lineItems?: LineItem[];
  availableDaysOfTheWeek?: FacilityAvailableDayDto[] | null;
  images: FacilityImage[];
  isPublished?: boolean;
  start?: Time;
  end?: Time;
  minDuration?: number;
  roomType?: RoomType;
  ownerId?: string;
}

export interface FacilityAvailableDayDto {
  day: DaysOfTheWeek;
}

export enum DaysOfTheWeek {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export interface SelectedDay {
  name: string;
  selected: boolean;
}

export interface FacilityForm {
  id?: string;
  name: string;
  description: string;
  eventColor: string;
  capacity?: number;
  images?: FacilityFormImage[];
  lineItems?: LineItem[];
  start?: Time;
  end?: Time;
  minDuration?: number;
  roomType?: RoomType;
  ownerId: string;
  availableDaysOfTheWeek?: FacilityAvailableDayDto[];
}

export interface FacilityBookingSearchRequest {
  date?: Date;
  capacity?: number;
  duration: number;
}

export interface FacilitySearchFormData {
  date?: Date;
  capacity?: number;
  duration?: number;
  roomType?: RoomType;
}
