import BaseLayout from '../../components/layouts/base-layout';
import {
  Flex,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  useToast,
  Text,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import {
  AppButton,
  AppColors,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  usePanel,
  useLoading,
  FacilitySelect,
  Organization,
  OrganizationEditForm,
  OrganizationFormData,
  useDialog,
  AppConfirm,
  useDataTable,
  AppPaginator,
  SelectListFilter,
  SelectOption,
  AppLoader,
  OrganizationType,
  AppText,
} from '@giflo/shared';
import { AppDataTable } from '@giflo/shared';
import React, { useEffect, useState } from 'react';
import {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useAllocateOrganizationFacilitiesMutation,
  useFetchOrganizationsByOptionsQuery,
  useFetchAllOrganizationsQuery,
} from '../../store/api/organizations';
import OrganizationForm from '../../components/forms/organization-form';
import {
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  DeleteIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import { useLazyFetchAllFacilitiesQuery } from '../../store/api/facilityApi';
import OrganizationFacilityForm from '../../components/forms/organization-facility-form';

const Organizations: React.FC = () => {
  const filterOptions: SelectOption[] = [
    {
      label: 'Active',
      value: true,
    },
    {
      label: 'Inactive',
      value: false,
    },
  ];
  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
    handleSearchChange,
  } = useDataTable<Organization>();

  const {
    data: organizations,
    isLoading,
    refetch: refetchOrganizations,
  } = useFetchOrganizationsByOptionsQuery(
    {
      isActive: true,
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: inActiveOrganizations, refetch: refetchInActiveOrganizations } =
    useFetchOrganizationsByOptionsQuery(
      {
        isActive: false,
        pageNumber: nextPaginationQueryParams.page,
        pageSize: nextPaginationQueryParams.pageSize,
        searchParam: nextPaginationQueryParams.searchList,
        sortParams: nextPaginationQueryParams.sortList,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (organizations?.pagination) {
      setPagination(organizations?.pagination);
    }
  }, [organizations]);

  const [saveOrganizationFacilities] =
    useAllocateOrganizationFacilitiesMutation();
  const [getFacilityList] = useLazyFetchAllFacilitiesQuery();
  const [saveOrganizationTrigger] = useCreateOrganizationMutation();
  const [editOrganizationTrigger] = useUpdateOrganizationMutation();
  const [deleteOrganizationTrigger] = useDeleteOrganizationMutation();
  const panel = usePanel();
  const dialog = useDialog();
  const toast = useToast();
  const { setLoading } = useLoading();

  const [activeFilter, setActiveFilter] = useState<boolean>(true);

  const handleAdd = () => {
    panel({
      title: 'Add Organization',
      render: (onSubmit) => {
        return (
          <OrganizationForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertOrganization(formData);
            }}
          />
        );
      },
    });
  };

  const handleEdit = (item: Organization) => {
    const organizationForm: OrganizationEditForm = {
      id: item.id,
      name: item.name,
      companyReg: item.companyReg,
      companyVAT: item.companyVAT,
      contractExpiryDate: item.contractExpiryDate
        ? new Date(item.contractExpiryDate)
        : new Date(),
      website: item.website,
      buildingUnit: item.buildingUnit,
      type: item.type,
      isActive: item.isActive,
    };
    panel({
      title: 'Edit Organization',
      render: (onSubmit) => {
        return (
          <OrganizationForm
            form={organizationForm}
            onSubmit={async (formData) => {
              onSubmit();
              await upsertOrganization(formData);
            }}
          />
        );
      },
    });
  };

  const handleFacilities = async (item: Organization) => {
    const facilities = await getFacilityList({}).unwrap();
    const selectList: FacilitySelect[] = facilities.map((facility) => {
      const foundOrgFacility = item.organizationFacilities?.find(
        (organizationFacility) =>
          organizationFacility.facilityId === facility.id
      );
      return {
        id: facility.id,
        name: facility.name,
        selected: foundOrgFacility ? true : false,
      };
    });
    panel({
      title: 'Facility Selection',
      render: (onSubmit) => {
        return (
          <OrganizationFacilityForm
            facilityList={selectList || []}
            onSubmit={async (formData) => {
              await saveOrganizationFacilities({
                organizationId: item.id,
                facilityIds: formData,
              });
              onSubmit();
              toast({
                title: 'Organization saved',
                description: "We've saved the organization",
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
              refetchOrganizations();
            }}
          />
        );
      },
    });
  };

  const handleDeleteConfirmation = (item: Organization) => {
    dialog({
      title: 'Delete Organizaion',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete ${item.name}?`}
            onConfirm={async () => {
              onSubmit();
              await handleDelete(item);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleDelete = async (item: Organization) => {
    setLoading(true);
    try {
      const deletePromise = deleteOrganizationTrigger(item.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: 'Organization Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected organization. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
    }
    toast({
      title: 'Organization Deleted',
      description: "We've deleted the organization you selected",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchOrganizations();
    setLoading(false);
  };

  const upsertOrganization = async (formData: any) => {
    setLoading(true);
    let eddittedOrganization = formData.id ? true : false;
    try {
      if (!eddittedOrganization) {
        const savingOrganization = formData as OrganizationFormData;
        await saveOrganizationTrigger({
          name: savingOrganization.name,
          companyReg: savingOrganization.companyReg,
          companyVAT: savingOrganization.companyVAT,
          contractExpiryDate: savingOrganization.contractExpiryDate,
          website: savingOrganization.website,
          buildingUnit: savingOrganization.buildingUnit,
          type: savingOrganization.type,
          isActive: true,
        }).unwrap();
      } else {
        const editOrganization = formData as OrganizationEditForm;
        await editOrganizationTrigger({
          id: editOrganization.id,
          name: editOrganization.name,
          companyReg: editOrganization.companyReg,
          companyVAT: editOrganization.companyVAT,
          contractExpiryDate: editOrganization.contractExpiryDate,
          website: editOrganization.website,
          buildingUnit: editOrganization.buildingUnit,
          type: editOrganization.type,
          isActive: true,
        }).unwrap();
      }
      toast({
        title: 'Organization saved',
        description: "We've saved the organization you've added",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Orgaization saving issue',
        description:
          "We've run into an issue while saving the organization, contact support for help!",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    refetchOrganizations();
    setLoading(false);
  };

  const renderDefaultTableActions = (item: Organization) => {
    return (
      <Menu>
        <MenuButton
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          size="xs"
          bgColor={'white'}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<CalendarIcon />}
            onClick={() => handleFacilities(item)}
          >
            Manage Facilities
          </MenuItem>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEdit(item)}>
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => handleDeleteConfirmation(item)}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const getOrganizationTypeRender = (type?: any) => {
    if (type === null || type === undefined) {
      return <AppText></AppText>;
    }

    switch (type) {
      case OrganizationType.EXTERNAL:
        return <AppText>External</AppText>;
      case OrganizationType.INTERNAL:
        return <AppText>Internal</AppText>;

      default:
        return <AppText></AppText>;
    }
  };

  const organizationColumns: ColumnDefinitionType<Organization>[] = [
    {
      key: 'dateCreated',
      type: ColumnSpecialTypes.date,
      header: 'date added',
      headerSortable: true,
      columnSearchable: false,
    },
    {
      key: 'contractExpiryDate',
      type: ColumnSpecialTypes.date,
      header: 'Contract Expiry Date',
      headerSortable: true,
      columnSearchable: false,
    },
    {
      key: 'buildingUnit',
      header: 'Unit',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'name',
      header: 'Name',
      headerSortable: false,
      columnSearchable: true,
    },
    {
      key: 'companyVAT',
      header: 'Company VAT',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'companyReg',
      header: 'Company Reg',
      headerSortable: false,
      columnSearchable: false,
    },
    {
      key: 'isActive',
      header: 'Active',
      render: (item) => (
        <>
          {item?.isActive === true ? (
            <CheckIcon color={'green'} w={'12px'} h={'12px'} />
          ) : (
            <CloseIcon color={'red'} w={'12px'} h={'12px'} />
          )}
        </>
      ),
    },

    {
      key: 'type',
      header: 'Type',
      render: (item) => <>{getOrganizationTypeRender(item.type)}</>,
    },
    {
      key: 'custom',
      header: 'Allocated Facilities',
      render: (item) => {
        return (
          <Tag borderRadius="full" colorScheme={'gray'}>
            <TagLabel>{item.organizationFacilities?.length}</TagLabel>
          </Tag>
        );
      },
    },
    {
      key: 'custom',
      header: 'actions',
      render: renderDefaultTableActions,
    },
  ];

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Flex direction={'column'} w={'full'}>
        <Flex justifyContent={'space-between'} m={4}>
          <Flex align={'center'} gap={4}>
            <Text fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
              {'Organizations'}
            </Text>
            <SelectListFilter
              w={'220px'}
              options={filterOptions}
              isInModal
              isMulti={false}
              placeholder="Organization Filter"
              onSelectionChange={async (item: SelectOption[]) => {
                setActiveFilter(item[0].value);
              }}
              showClear={false}
            />
          </Flex>
          <Flex alignItems={'center'}>
            <AppButton
              bgColor={AppColors.tertiary}
              color={'white'}
              onClick={handleAdd}
              size="sm"
              borderRadius={0}
              mr="4"
            >
              Add Organization
            </AppButton>
          </Flex>
        </Flex>

        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            data={
              activeFilter
                ? organizations?.data || []
                : inActiveOrganizations?.data || []
            }
            noDataMessage="No Organizations added yet, start by adding some!"
            columns={organizationColumns || []}
            selectableRows={false}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={handleOnPageChange}
          ></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};
export default Organizations;
