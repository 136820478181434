import { useEffect, useState } from 'react';
import { Pagination } from '../models/shared';

const defaultPageSizes = [1, 10, 25, 50, 75, 100];

const usePagination = (pagination: Pagination | undefined) => {
  const [currentPage, setCurrentPage] = useState(pagination?.pageNumber || 0);
  const [currentPageSize, setCurrentPageSize] = useState(
    pagination?.pageSize || 25
  );
  const [totalRecords, setTotalRecords] = useState(pagination?.totalCount || 0);
  const [currentPageCount, setCurrentPageCount] = useState(
    pagination?.totalPages || 0
  );
  const [canGoNextPage, setCanGoNextPage] = useState(false);

  const [canGoPreviousPage, setCanGoPreviousPage] = useState(false);
  const [currentPageSizes, setCurrentPageSizes] = useState(defaultPageSizes);

  useEffect(() => {
    setPageNavigationBlockers(currentPage, currentPageCount);
  }, []);

  const goToNextPage = () => {
    console.log('goToNextPage currentPage', currentPage);
    const nextPage = currentPage + 1;
    if (nextPage <= currentPageCount) {
      setCurrentPage(nextPage);
      setPageNavigationBlockers(nextPage, currentPageCount);
    }
  };
  const goToPreviousPage = () => {
    console.log('goToPreviousPage currentPage', currentPage);
    const previousPage = currentPage - 1;
    if (previousPage >= 0) {
      setCurrentPage(previousPage);
      setPageNavigationBlockers(previousPage, currentPageCount);
    }
  };
  const goToFirstPage = () => {
    setCurrentPage(1);
    setPageNavigationBlockers(1, currentPageCount);
  };
  const goToLastPage = () => {
    setCurrentPage(currentPageCount);
    setPageNavigationBlockers(currentPageCount, currentPageCount);
  };
  const setPage = (page: number) => {
    if (page >= 0 && page <= currentPageCount) {
      setCurrentPage(page);
      setPageNavigationBlockers(page, currentPageCount);
    }
  };
  const setPageSize = (pageSize: number) => {
    setCurrentPageSize(pageSize);
    setPageNavigationBlockers(currentPage, currentPageCount);
  };

  const setPageNavigationBlockers = (page: number, pageSize: number) => {
    setCanGoNext(page, pageSize);
    setCanGoPrevious(page);
  };

  const setCanGoNext = (page: number, pageSize: number) => {
    if (page >= pageSize) {
      setCanGoNextPage(false);
    } else {
      setCanGoNextPage(true);
    }
  };

  const setCanGoPrevious = (page: number) => {
    if (page - 1 !== 0) {
      setCanGoPreviousPage(true);
    } else {
      setCanGoPreviousPage(false);
    }
  };

  const overrideDefaultPageSizes = (sizes: number[]) => {
    setCurrentPageSizes(sizes);
  };

  const setNewPaginationProps = (pagination: Pagination | undefined) => {
    setCurrentPage(pagination?.pageNumber || 0);
    setCurrentPageSize(pagination?.pageSize || 25);
    setTotalRecords(pagination?.totalCount || 0);
    setCurrentPageCount(pagination?.totalPages || 0);
    setPageNavigationBlockers(
      pagination?.pageNumber || 0,
      pagination?.totalPages || 0
    );
  };

  return {
    currentPage,
    currentPageSize,
    totalRecords,
    currentPageCount,
    goToNextPage,
    goToPreviousPage,
    goToFirstPage,
    goToLastPage,
    setPage,
    canGoNextPage,
    canGoPreviousPage,
    overrideDefaultPageSizes,
    currentPageSizes,
    setPageSize,
    setNewPaginationProps,
  };
};

export default usePagination;
