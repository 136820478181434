import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  Booking,
  FacilityBookingResult,
  AvailableBookingSlotResponse,
  AvailableBookingSlotRequest,
  OrganizationBookingCreateRequest,
  OrganizationBookingsByOptionRequest,
  PaginatedOrganizationBookingsByOptionRequest,
  PaginatedData,
} from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';

export const organizationBookingApi = createApi({
  reducerPath: 'organizationBookingApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchAllOrganizationBookings: build.query<
      Booking[],
      OrganizationBookingsByOptionRequest
    >({
      query: (body) => ({
        url: `/organizationbooking?orgId=${body.organizationId}&facilityId=${body.facilityId}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationBookingsByOptions: build.query<
      PaginatedData<Booking>,
      PaginatedOrganizationBookingsByOptionRequest
    >({
      query: (data) => ({
        url: `/organizationbooking/by/options`,
        method: 'POST',
        body: JSON.stringify(data),
      }),
      transformResponse: (response: PaginatedData<Booking>) => {
        return response;
      },
    }),
    fetchTodayOrganizationBookings: build.query<Booking[], any>({
      query: () => ({
        url: '/organizationbooking/by/today',
        method: 'GET',
      }),
    }),
    fetchOrganizationBookingCountByFacility: build.query<
      FacilityBookingResult[],
      number
    >({
      query: (month) => ({
        url: `/organizationbooking/count/by/facility/by/month/${month}`,
        method: 'GET',
      }),
    }),
    fetchOrganizationBookingCountByMonth: build.query<number, number>({
      query: (month) => ({
        url: `/organizationbooking/by/month/${month}`,
        method: 'GET',
      }),
    }),
    fetchAvailableTimeSlots: build.mutation<
      AvailableBookingSlotResponse,
      AvailableBookingSlotRequest
    >({
      query: (params) => ({
        url: '/organizationbooking/available/time/slots',
        method: 'POST',
        body: params,
      }),
    }),
    fetchCurrentMonthOrganizationBookingCount: build.query<number, any>({
      query: () => ({
        url: '/organizationbooking/by/current/month',
        method: 'GET',
      }),
    }),
    fetchOrganizationBookingById: build.query<Booking, string>({
      query: (id) => ({
        url: `/organizationbooking/${id}`,
        method: 'GET',
      }),
    }),
    createOrganizationBooking: build.mutation<
      Booking,
      OrganizationBookingCreateRequest
    >({
      query: (body) => ({
        url: '/organizationbooking/create',
        method: 'POST',
        body: body,
      }),
      transformResponse: (response: Booking) => {
        return response;
      },
    }),
    deleteOrganizationBooking: build.mutation<string, string>({
      query: (id) => ({
        url: `/organizationbooking/delete/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchAllOrganizationBookingsQuery,
  useFetchOrganizationBookingsByOptionsQuery,
  useFetchTodayOrganizationBookingsQuery,
  useFetchCurrentMonthOrganizationBookingCountQuery,
  useFetchAvailableTimeSlotsMutation,
  useFetchOrganizationBookingCountByMonthQuery,
  useFetchOrganizationBookingCountByFacilityQuery,
  useFetchOrganizationBookingByIdQuery,
  useCreateOrganizationBookingMutation,
  useDeleteOrganizationBookingMutation,
} = organizationBookingApi;
