import React from 'react';
import { Stack, Flex } from '@chakra-ui/react';
import {
  AppText,
  AppCarousel,
  AppButton,
  AppColors,
  Booking,
} from '@giflo/shared';
import AppTextRenderer from '@giflo/shared/lib/components/app-text-renderer/app-text-renderer';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdPeopleOutline } from 'react-icons/md';
import { format } from 'date-fns';

type FacilityDetailProps = {
  booking: Booking;
  deleteBooking?: boolean;
  handleDelete: () => void;
};

const BookingDetails: React.FC<FacilityDetailProps> = ({
  booking,
  deleteBooking = true,
  handleDelete,
}) => {
  return (
    <>
      <Stack spacing={4} color={{ base: 'black' }}>
        <AppCarousel facility={booking.facility} />
        <AppText fontSize={'xl'} fontWeight={'500'}>
          {booking.facility.name}
        </AppText>
        <Flex>
          <AppTextRenderer markdown={booking.facility!.description} />
        </Flex>
        <Flex direction={'column'} gap={3}>
          <Flex gap={2} justify={'flex-start'}>
            <MdPeopleOutline />
            <AppText>{booking.facility.capacity}</AppText>
          </Flex>
          <Flex gap={2}>
            <AiOutlineClockCircle />
            <AppText>{`${booking.facility.start?.hour}:${
              booking.facility.start?.minute === 0
                ? '00'
                : booking.facility.start?.minute
            } - ${booking.facility.end?.hour}:${
              booking.facility.end?.minute === 0
                ? '00'
                : booking.facility.end?.minute
            }`}</AppText>
          </Flex>
        </Flex>
        <Flex direction={'column'} gap={2}>
          <AppText fontSize={'md'} fontWeight={'500'}>
            Line Items
          </AppText>
          {booking.facility!.lineItems?.map((x) => {
            return <AppText key={x.lineItem}>{x.lineItem}</AppText>;
          })}
        </Flex>
        {booking.start && booking.day && booking.end && (
          <Flex
            w={'full'}
            justify={'end'}
            p={2}
            bgColor={AppColors.tertiary}
            direction={'column'}
          >
            <Flex direction={'column'} gap={3} w={'full'} p={4} color={'white'}>
              <AppText fontSize={'2xl'} fontWeight={'600'}>
                Booking Details
              </AppText>
              <Flex w={'full'} justify={'space-between'}>
                <AppText>
                  {format(new Date(booking.day), 'dd MMM yyyy')}
                </AppText>
                <Flex alignItems={'center'}>
                  <AiOutlineClockCircle />
                  <AppText ml={2} fontWeight={'bold'}>
                    {`${booking.start?.hour}:${
                      booking.start?.minute === 0 ? '00' : booking.start?.minute
                    } - ${booking.end?.hour}:${
                      booking.end?.minute === 0 ? '00' : booking.end?.minute
                    }`}
                  </AppText>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}

        <AppButton
          display={deleteBooking ? 'flex' : 'none'}
          bgColor={AppColors.tertiary}
          color={'white'}
          borderRadius={0}
          onClick={() => {
            handleDelete();
          }}
        >
          Delete Booking
        </AppButton>
      </Stack>
    </>
  );
};
export default BookingDetails;
