import BaseLayout from '../../components/layouts/base-layout';
import { Flex, useColorModeValue, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  AppButton,
  AppText,
  CalendarEvent,
  usePanel,
  AppCalendar,
  useLoading,
  Booking,
  useDialog,
  AppConfirm,
  SelectOption,
  SelectListFilter,
  AppColors,
  AppLoader,
} from '@giflo/shared';
import {
  useDeleteOrganizationBookingMutation,
  useFetchAllOrganizationBookingsQuery,
} from '../../store/api/organizationBookingApi';
import { add } from 'date-fns';
import BookingFlowForm from '../../components/forms/booking-flow-form';
import { useFetchAllOrganizationsQuery } from '../../store/api/organizations';
import FacilityDetails from '../../components/forms/booking-details';
import { useFetchAllFacilitiesQuery } from '../../store/api/facilityApi';

const BookingsCalendar: React.FC = () => {
  const { setLoading } = useLoading();
  const [deleteBookingTrigger] = useDeleteOrganizationBookingMutation();
  const dialog = useDialog();
  const panel = usePanel();
  const toast = useToast();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const { data: organizations } = useFetchAllOrganizationsQuery({
    refetchOnMountArgChange: true,
  });

  const { data: facilities } = useFetchAllFacilitiesQuery({
    refetchOnMountArgChange: true,
  });

  const [organizationOptions, setOrganizationOptions] = useState<
    SelectOption[]
  >([]);
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>('');

  const [facilityOptions, setFacilityOptions] = useState<SelectOption[]>([]);
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>('');

  const { data: bookings, refetch: refetchBookings, isLoading } =
    useFetchAllOrganizationBookingsQuery({
      organizationId: selectedOrganizationId,
      facilityId: selectedFacilityId,
    });

  const [events, setEvents] = useState<CalendarEvent[]>([]);

  const handleBookingDelete = (booking: Booking) => {
    panel({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            message={`Are you sure you want to delete the booking for ${booking.organization.name}?`}
            onConfirm={async () => {
              onSubmit();
              await handleDeleteItem(booking.id);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleDeleteItem = async (item: string) => {
    setLoading(true);
    try {
      const deletePromise = deleteBookingTrigger(item ?? '').unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: 'Booking Delete Error',
        status: 'error',
        description:
          "We've run into a problem deleting the Booking you selected. Contact support for help.",
        isClosable: true,
        duration: 9000,
      });
      setLoading(false)
    }
    toast({
      title: 'Booking Deleted',
      status: 'success',
      description: "We've deleted the Booking you selected",
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchBookings();
  };

  const handleAdd = (slotInfo: any) => {
    panel({
      size: 'xl',
      title: 'Add Booking',
      render: (onSubmit) => {
        return (
          <BookingFlowForm
            handleSubmit={() => {
              onSubmit();
              refetchBookings();
            }}
          />
        );
      },
    });
  };

  const handleView = (event: CalendarEvent) => {
    const booking = bookings?.find((x) => x.id === event.resource);
    panel({
      title: 'Booking Details',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <FacilityDetails
            booking={booking!}
            handleDelete={() => {
              onSubmit();
              handleBookingDelete(booking!);
            }}
          />
        );
      },
    });
  };

  useEffect(() => {
    if (bookings) {
      const eventList: CalendarEvent[] = bookings?.map((booking) => {
        const startDate = new Date(
          new Date(booking.day!).setUTCHours(-2, 0, 0, 0)
        );
        return {
          title: `${booking.organization.name} at ${booking.facility.name}`,
          allDay: false,
          start: add(startDate, {
            hours: booking.start?.hour,
            minutes: booking.start?.minute,
          }),
          end: add(startDate, {
            hours: booking.end?.hour,
            minutes: booking.end?.minute,
          }),
          resource: booking.id,
        };
      });
      setEvents(eventList);
    }
  }, [bookings]);

  useEffect(() => {
    if (organizations) {
      const options: SelectOption[] = organizations.map((x) => {
        return {
          label: x.isActive === false ? `${x.name} (InActive)` : x.name,
          value: x.id,
        };
      });

      setOrganizationOptions(options);
    }
  }, [organizations]);

  useEffect(() => {
    if (facilities) {
      const options: SelectOption[] = facilities.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });

      setFacilityOptions(options);
    }
  }, [facilities]);

  return (
    <BaseLayout>
    { isLoading && (<AppLoader />)}
      <Flex direction={'column'} w={'full'} h={'100%'} overflowY={'scroll'}>
        <Flex justify={'space-between'} m={4} align={'center'}>
          <Flex alignItems={'center'}>
            <AppText
              mr={8}
              color={textColorPrimary}
              fontWeight="bold"
              fontSize="2xl"
            >
              Bookings
            </AppText>

            <SelectListFilter
              mr={8}
              options={facilityOptions}
              isInModal
              isMulti={false}
              placeholder="Facility Filter"
              onSelectionChange={async (item: SelectOption[]) => {
                if (item && item.length > 0) {
                  const facilityId = item[0].value;
                  setSelectedFacilityId(facilityId);
                } else {
                  setSelectedFacilityId('');
                  setTimeout(() => {
                    refetchBookings();
                  }, 500);
                }
              }}
              showClear={true}
            />
            <SelectListFilter
              options={organizationOptions}
              isInModal
              isMulti={false}
              placeholder="Organization Filter"
              onSelectionChange={async (item: SelectOption[]) => {
                if (item && item.length > 0) {
                  const orgId = item[0].value;
                  setSelectedOrganizationId(orgId);
                } else {
                  setSelectedOrganizationId('');
                  setTimeout(() => {
                    refetchBookings();
                  }, 500);
                }
              }}
              showClear={true}
            />
          </Flex>

          <AppButton
            bgColor={AppColors.tertiary}
            color={'white'}
            size={'sm'}
            borderRadius={0}
            onClick={handleAdd}
          >
            Add Booking
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
          overflowY={'scroll'}
          h={'inherit'}
        >
          <AppCalendar
            events={events || []}
            handleSelect={handleAdd}
            handleSelectEdit={handleView}
          />
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default BookingsCalendar;
