import React from 'react';
import { FlexProps, Flex } from '@chakra-ui/react';
import { AppText } from '../app-text/app-text';
import { AppButton } from '../app-button/app-button';

export type AppConfirmProps = {
  message?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
} & FlexProps;

export const AppConfirm: React.FC<AppConfirmProps> = ({
  message = 'Are you sure?',
  confirmText = 'Ok',
  cancelText = 'Cancel',
  children,
  onConfirm,
  onCancel,
  ...props
}) => {
  return (
    <Flex direction={'column'} {...props}>
      <AppText>{message}</AppText>
      {children}
      <Flex
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        py={2}
      >
        <AppButton mt={4} onClick={() => onConfirm && onConfirm()}>
          {confirmText}
        </AppButton>
        <AppButton mt={4} onClick={() => onCancel && onCancel()}>
          {cancelText}
        </AppButton>
      </Flex>
    </Flex>
  );
};
