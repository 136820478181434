import { Box, Flex, Image } from '@chakra-ui/react';
import { AppButton } from '../app-button';
import { AppText } from '../app-text';
import { AppColors } from '../../theme';
import { Booking } from '../../models';
import { format } from 'date-fns';
import { RiDeleteBin2Line, RiEyeLine } from 'react-icons/ri';

export interface AppBookingCardProps {
  booking: Booking;
  bookingDetails?: boolean;
  deleteBooking?: boolean;
  bgColor?: string;
  handleBookingDetails: (item: Booking) => void;
  handleDelete: (item: Booking) => void;
}

const AppBookingCard: React.FC<AppBookingCardProps> = ({
  booking,
  bookingDetails = true,
  deleteBooking = true,
  bgColor,
  handleBookingDetails,
  handleDelete,
}) => {
  return (
    <Flex
      justify={'space-between'}
      align={{ base: 'center', lg: 'auto' }}
      borderRadius={0}
      w={'full'}
      direction={{ base: 'column', lg: 'row' }}
      gap={3}
      p={4}
      key={booking.id}
      color={'white'}
      bgColor={bgColor}
    >
      <Flex
        gap={3}
        align={'center'}
        direction={{ base: 'column', lg: 'row' }}
        justify={'space-between'}
      >
        <Image
          src={booking.facility.images[0].imageUrl}
          w={{ base: '250px', lg: '150px' }}
        />
        <Flex
          direction={'column'}
          align={{ base: 'center', lg: 'start' }}
          gap={1}
          w={{ base: 'full', lg: 'auto' }}
        >
          <AppText
            color={AppColors.tertiary}
          >
            {booking.organization.name}
          </AppText>
          <AppText
            color={AppColors.tertiary}
          >
            {booking.facility.name}
          </AppText>
          <AppText color={AppColors.tertiary}>
            {format(new Date(booking.day!), 'dd MMM yyyy')}
          </AppText>
          <AppText color={AppColors.tertiary}>
            {`${booking.start?.hour}:${
              booking.start?.minute === 0 ? '00' : booking.start?.minute
            } - ${booking.end?.hour}:${
              booking.end?.minute === 0 ? '00' : booking.end?.minute
            }`}
          </AppText>
        </Flex>
      </Flex>

      <Flex align={'center'} justify={'center'} h={'inherit'} gap={3} display={bookingDetails || deleteBooking ? 'flex' : 'none'}>
        <AppButton
          display={bookingDetails ? 'flex' : 'none'}
          onClick={() => handleBookingDetails(booking)}
          rightIcon={<RiEyeLine />}
          borderRadius={0}
          color={'white'}
          bgColor={AppColors.tertiary}
          size={'sm'}
        >
          View
        </AppButton>
        <AppButton
          display={deleteBooking ? 'flex' : 'none'}
          onClick={() => handleDelete(booking)}
          rightIcon={<RiDeleteBin2Line />}
          borderRadius={'full'}
          color={'white'}
          bgColor={AppColors.tertiary}
        >
          Delete
        </AppButton>
      </Flex>
    </Flex>
  );
};

export default AppBookingCard;
