import { AppText } from '@giflo/shared';
import { Flex } from '@chakra-ui/react';
import { UserRole } from '@giflo/shared';

type UserRoleCellProps = {
  item: UserRole;
};

const UserRoleCell: React.FC<UserRoleCellProps> = ({ item }) => {
  return (
    <Flex>
      <AppText>{item === UserRole.USER ? 'User' : 'Admin'}</AppText>
    </Flex>
  );
};
export default UserRoleCell;
