import { createSlice } from '@reduxjs/toolkit';
import { Facility } from '@giflo/shared';
import { RootState } from '../store';
import { facilityApi } from '../api/facilityApi';

interface FacilityState {
  facilities: Facility[] | undefined;
}

const initialState: FacilityState = {
  facilities: [],
};

export const facilitySlice = createSlice({
  name: 'facilities',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      facilityApi.endpoints.fetchAllFacilities.matchFulfilled,
      (state, { payload }) => {
        return { ...state, facilities: payload };
      }
    );
  },
  reducers: {},
});

export const {} = facilitySlice.actions;

export const selectFacilities = (state: RootState) => state.facility.facilities;
