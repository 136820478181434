import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  AppDataTable,
  ColumnDefinitionType,
  ColumnSpecialTypes,
  AppColors,
  usePanel,
  AppButton,
  useLoading,
  useDialog,
  AppConfirm,
  useDataTable,
  AppPaginator,
  AppLoader,
} from '@giflo/shared';
import BaseLayout from '../../components/layouts/base-layout';
import { ChevronDownIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  useFetchAllContactsQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useFetchContactsByOptionsQuery,
} from '../../store/api/contactsApi';
import {
  Contacts,
  ContactsEditForm,
  ContactsForm,
} from '../../models/contacts';
import ContactForm from '../../components/forms/contact-form';
import { useEffect } from 'react';

const ContactsPage: React.FC = () => {
  const {
    handleOnPageChange,
    nextPaginationQueryParams,
    pagination,
    setPagination,
    handleSortByColumnClick,
    handleSearchChange,
  } = useDataTable<Contacts>();

  const dialog = useDialog();

  const {
    data: contacts,
    isLoading,
    refetch: refetchContacts,
  } = useFetchContactsByOptionsQuery(
    {
      pageNumber: nextPaginationQueryParams.page,
      pageSize: nextPaginationQueryParams.pageSize,
      searchParam: nextPaginationQueryParams.searchList,
      sortParams: nextPaginationQueryParams.sortList,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (contacts?.pagination) {
      setPagination(contacts?.pagination);
    }
  }, [contacts]);

  const toast = useToast();
  const panel = usePanel();

  const { setLoading } = useLoading();
  const [createContactTrigger] = useCreateContactMutation();
  const [editContactTrigger] = useUpdateContactMutation();
  const [deleteContactTrigger] = useDeleteContactMutation();

  const handleDeleteConfirmation = (contact: Contacts) => {
    dialog({
      title: 'Delete',
      render: (onSubmit, onCancel) => {
        return (
          <AppConfirm
            mx={4}
            message={`Are you sure you want to delete ${contact.name}?`}
            onConfirm={async () => {
              onSubmit();
              await handleDeleteContact(contact);
            }}
            onCancel={() => {
              onCancel();
            }}
          />
        );
      },
    });
  };

  const handleDeleteContact = async (contact: Contacts) => {
    setLoading(true);
    try {
      const deletePromise = deleteContactTrigger(contact.id).unwrap();
      await deletePromise;
    } catch (e) {
      toast({
        title: 'Contact delete error',
        status: 'error',
        description:
          "We've run into a problem deleting the selected contact. Contact support for help",
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    toast({
      title: 'Contact Deleted',
      description: "We've deleted the selected contact",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    setLoading(false);
    refetchContacts();
  };

  const upsertContacts = async (formData: any) => {
    let eddittedContact = formData.id ? true : false;
    setLoading(true);
    try {
      if (!eddittedContact) {
        const savingContact = formData as ContactsForm;
        await createContactTrigger({
          name: savingContact.name,
          companyName: savingContact.companyName,
          emailAddress: savingContact.emailAddress,
          number: savingContact.number,
        }).unwrap();
      } else {
        const editContact = formData as ContactsEditForm;
        await editContactTrigger({
          id: editContact.id ?? '',
          name: editContact.name,
          companyName: editContact.companyName,
          emailAddress: editContact.emailAddress,
          number: editContact.number,
        }).unwrap();
      }
      toast({
        title: 'Contact saved',
        description: "We've saved the contact you've added",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Contact saving issue',
        description: "We've run into a problem saving the contact.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
    setLoading(false);
    refetchContacts();
  };

  const handleAdd = () => {
    panel({
      title: 'Add Contact',
      render: (onSubmit) => {
        return (
          <ContactForm
            onSubmit={async (formData) => {
              onSubmit();
              await upsertContacts(formData);
              console.log('formData', formData);
            }}
          />
        );
      },
    });
  };

  const handleEdit = (contact: Contacts) => {
    const contactEditForm: ContactsEditForm = {
      id: contact.id,
      name: contact.name,
      companyName: contact.companyName,
      emailAddress: contact.emailAddress,
      number: contact.number,
    };
    panel({
      title: 'Update Contact',
      render: (onSubmit) => {
        return (
          <ContactForm
            form={contactEditForm}
            onSubmit={async (formData) => {
              console.log('edit formData', formData);
              onSubmit();
              await upsertContacts(formData);
            }}
          />
        );
      },
    });
  };

  const renderDefaultTableActions = (item: Contacts) => {
    return (
      <Menu>
        <MenuButton
          size="xs"
          bgColor={'white'}
          color={AppColors.primary}
          border={`1px solid ${AppColors.appBorder}`}
          as={Button}
          rightIcon={<ChevronDownIcon />}
          borderRadius="full"
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem icon={<SettingsIcon />} onClick={() => handleEdit(item)}>
            Edit
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => handleDeleteConfirmation(item)}
          >
            Delete
          </MenuItem>
        </MenuList>
      </Menu>
    );
  };

  const contactColumns: ColumnDefinitionType<Contacts>[] = [
    {
      key: 'dateCreated',
      header: 'Date Added',
      type: ColumnSpecialTypes.date,
      columnSearchable: false,
      headerSortable: true,
    },
    {
      key: 'name',
      header: 'Name',
      columnSearchable: true,
    },
    {
      key: 'companyName',
      header: 'Company',
      columnSearchable: true,
    },
    {
      key: 'emailAddress',
      header: 'Email',
      columnSearchable: true,
    },
    {
      key: 'number',
      header: 'Cell No.',
      columnSearchable: false,
    },
    {
      key: 'custom',
      header: 'Actions',
      render: renderDefaultTableActions,
    },
  ];

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Flex direction={'column'} w={'full'}>
        <Flex justifyContent={'space-between'} m={4} align={'center'}>
          <Text fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
            {'Contacts'}
          </Text>
          <AppButton
            bgColor={AppColors.tertiary}
            color={'white'}
            size={'sm'}
            borderRadius={0}
            mr={4}
            onClick={handleAdd}
          >
            Add Contact
          </AppButton>
        </Flex>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          rounded={'2xl'}
          padding={4}
          m={4}
          flexDir={'column'}
          flex={1}
        >
          <AppDataTable
            columns={contactColumns}
            data={contacts?.data || []}
            noDataMessage="No contacts added yet, start by adding some!"
            selectableRows={true}
            onSearchInputChange={handleSearchChange}
            onSortByColumnClick={handleSortByColumnClick}
          />
          <AppPaginator
            pagination={pagination}
            onPageChange={handleOnPageChange}
          ></AppPaginator>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default ContactsPage;
