import { Base } from './base';
import { Facility, RoomType } from './facility';
import { ByOptionsRequest } from './shared';

export interface Organization extends Base {
  name: string;
  organizationFacilities?: OrganizationFacilityDto[];
  contractExpiryDate?: Date;
  companyVAT?: string;
  companyReg?: string;
  website?: string;
  isActive: boolean;
  buildingUnit?: string;
  type?: OrganizationType;
  bookings?: OrganizationBookingRequest[];
  facilityTypes?: OrganizationFacilityType[];
}

export interface OrganizationsByOptionsRequest extends ByOptionsRequest {
  isActive: boolean;
}

export enum OrganizationType {
  INTERNAL,
  EXTERNAL,
}

export interface OrganizationFacilityDto extends Base {
  organizationId: string;
  facilityId: string;
  facility?: Facility;
}

export interface FacilityTypes {
  data: OrganizationFacilityType[];
}

export interface OrganizationFacilityType {
  typeName: string;
  type: RoomType;
  image: string;
}

export interface OrganizationFormData {
  name: string;
  contractExpiryDate: Date;
  companyVAT?: string;
  companyReg?: string;
  website?: string;
  isActive: boolean;
  buildingUnit?: string;
  type?: OrganizationType;
}

export interface OrganizationEditForm extends OrganizationFormData {
  id: string;
}

export interface OrganizationSelect {
  id: string;
  name: string;
  selected: boolean;
}

export interface OrganizationSearchRequest {
  name: string;
}

export interface OrganizationSearchResponse {
  id: string;
  name: string;
}

export interface FacilitySelect {
  id: string;
  name: string;
  selected: boolean;
}

export interface OrganizationBookingRequest {
  id?: string;
  facilityId?: string;
  facilities: string[];
}

export interface FacilitySearchRequest {
  name: string;
}

export interface FacilitySearchResponse {
  id: string;
  name: string;
}

export interface OrganizationFacilityMappingRequest {
  organizationId: string;
  facilityIds: string[];
}
