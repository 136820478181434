import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { AppButton, AppColors, FacilitySelect } from '@giflo/shared';

type FacilityFormProps = {
  facilityList: FacilitySelect[];
  isEdit?: boolean;
  onSubmit: (data: string[]) => void;
};

export const FacilitySearchDefaultValues = {
  name: '',
};

const OrganizationFacilityForm: React.FC<FacilityFormProps> = ({
  facilityList,
  onSubmit,
}) => {
  const [facilities, setFacilities] = useState<FacilitySelect[]>([]);

  useEffect(() => {
    if (facilityList) {
      setFacilities(facilityList);
    }
  }, [facilityList]);

  const submitForm = () => {
    const selectedFacilities = facilities.filter((x) => x.selected);
    const facilityIds = selectedFacilities.map((x: FacilitySelect) => x.id);
    onSubmit(facilityIds);
  };

  const changeFacility = (facilityId: string, isSelected: boolean) => {
    const facilityCopy: FacilitySelect[] = JSON.parse(
      JSON.stringify(facilities)
    );
    const facilityIndex = facilityCopy.findIndex((x) => x.id === facilityId);
    facilityCopy[facilityIndex].selected = isSelected;
    setFacilities(facilityCopy);
  };

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Box mt={4}>
        {facilities?.map((facility) => (
          <Box key={facility.id}>
            <Checkbox
              colorScheme={'gray'}
              key={facility.id}
              isChecked={facility.selected}
              onChange={(e) => changeFacility(facility.id, e.target.checked)}
            >
              {facility.name}
            </Checkbox>
          </Box>
        ))}
      </Box>

      <AppButton
        bgColor={AppColors.primary}
        color={'white'}
        w={'full'}
        mt={8}
        onClick={() => submitForm()}
      >
        Save
      </AppButton>
    </Flex>
  );
};

export default OrganizationFacilityForm;
