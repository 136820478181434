import {
  Box,
  Flex,
  Grid,
  GridItem,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import BaseLayout from '../../components/layouts/base-layout';
import {
  useFetchCurrentMonthOrganizationBookingCountQuery,
  useFetchOrganizationBookingCountByMonthQuery,
  useFetchOrganizationBookingCountByFacilityQuery,
  useFetchTodayOrganizationBookingsQuery,
} from '../../store/api/organizationBookingApi';
import {
  useFetchOrganizationCountQuery,
  useFetchTopOrganizationQuery,
} from '../../store/api/organizations';
import {
  AppButton,
  AppCard,
  AppColors,
  AppLoader,
  AppText,
  Booking,
  SelectListFilter,
  SelectOption,
  useLoading,
  usePanel,
} from '@giflo/shared';
import AppBookingCard from '@giflo/shared/lib/components/app-booking-card/app-admin-booking-card';
import { BsPerson } from 'react-icons/bs';
import BookingDetails from '../../components/forms/booking-details';
import { useEffect, useState } from 'react';

const monthOptions: SelectOption[] = [
  {
    label: 'January',
    value: 1,
  },
  {
    label: 'February',
    value: 2,
  },
  {
    label: 'March',
    value: 3,
  },
  {
    label: 'April',
    value: 4,
  },
  {
    label: 'May',
    value: 5,
  },
  {
    label: 'June',
    value: 6,
  },
  {
    label: 'July',
    value: 7,
  },
  {
    label: 'August',
    value: 8,
  },
  {
    label: 'September',
    value: 9,
  },
  {
    label: 'October',
    value: 10,
  },
  {
    label: 'November',
    value: 11,
  },
  {
    label: 'December',
    value: 12,
  },
];

const Dashboard: React.FC = () => {
  const today = new Date();
  const month = today.getMonth() + 1;
  const panel = usePanel();
  const { setLoading } = useLoading();
  const [bookings, setBookings] = useState<Booking[]>();

  const [selectedLastMonth, setSelectedLastMonthFilter] =
    useState<number>(month);

  const {
    data: todayBookings,
    refetch: refetchBookings,
    isLoading,
  } = useFetchTodayOrganizationBookingsQuery({
    refetchOnMountArgChange: true,
  });

  const { data: topOrg } = useFetchTopOrganizationQuery({
    refetchOnMountArgChange: true,
  });

  const { data: orgCount } = useFetchOrganizationCountQuery({
    refetchOnMountArgChange: true,
  });

  const { data: bookingCountByFacilityList } =
    useFetchOrganizationBookingCountByFacilityQuery(selectedLastMonth ?? 1, {
      refetchOnMountOrArgChange: true,
    });

  const handleBookingDetails = (booking: Booking) => {
    panel({
      title: 'Booking Details',
      size: 'lg',
      render: (onSubmit) => {
        return (
          <BookingDetails
            booking={booking}
            handleDelete={() => {}}
            deleteBooking={false}
          />
        );
      },
    });
  };

  const getDefaultMonth = () => {
    const currentMonth = monthOptions.find((x) => x.value === month);
    return currentMonth ? currentMonth : monthOptions[0];
  };

  return (
    <BaseLayout>
      {isLoading && <AppLoader />}
      <Box p={'20px'} w="100%" h={'90vh'}>
        <Flex direction={'row'} h={'100%'} gap={4}>
          <AppCard flex={1}>
            <AppText variant={'h3'}>Today's Bookings</AppText>

            <Flex direction={'column'} mt={4} gap={4} overflow={'scroll'}>
              {todayBookings &&
                todayBookings.map((booking) => (
                  <AppBookingCard
                    bgColor={AppColors.appBorder}
                    key={booking.id}
                    booking={booking}
                    handleBookingDetails={() => {
                      handleBookingDetails(booking);
                    }}
                    handleDelete={() => {}}
                    deleteBooking={false}
                  />
                ))}
            </Flex>
          </AppCard>
          <Flex flex={1} flexDirection={'column'}>
            <Box>
              <Flex gap={4}>
                <AppCard flex={1}>
                  <Flex justifyContent={'space-between'}>
                    <Flex flexDirection={'column'} pl={{ base: 2, md: 4 }}>
                      <AppText variant={'body'}>Tenant Count (Active)</AppText>
                      <AppText mt={2} variant={'body'} fontWeight={'bold'}>
                        {orgCount}
                      </AppText>
                    </Flex>
                  </Flex>
                </AppCard>
                <AppCard flex={1}>
                  <Flex justifyContent={'space-between'}>
                    <Flex flexDirection={'column'} pl={{ base: 2, md: 4 }}>
                      <AppText variant={'body'}>Most Booking Tenant</AppText>
                      <AppText mt={2} variant={'body'} fontWeight={'bold'}>
                        {topOrg ? topOrg.name : ''}
                      </AppText>
                    </Flex>
                  </Flex>
                </AppCard>
              </Flex>
            </Box>

            <AppCard flex={1}>
              <Flex
                justifyContent={'space-between'}
                flexDirection={'row'}
                pl={{ base: 2, md: 4 }}
              >
                <Box>
                  <AppText variant={'body'}>
                    Bookings Breakdown by Month
                  </AppText>
                </Box>

                <SelectListFilter
                  w={'220px'}
                  options={monthOptions}
                  isInModal
                  isMulti={false}
                  defaultOption={getDefaultMonth()}
                  onSelectionChange={async (item: SelectOption[]) => {
                    setSelectedLastMonthFilter(item[0].value);
                  }}
                  showClear={false}
                />
              </Flex>
              <TableContainer mt={4}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Facility</Th>
                      <Th>Booking Count</Th>
                      <Th>Total Hours</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {bookingCountByFacilityList &&
                      bookingCountByFacilityList.map((facility, index) => {
                        return (
                          <Tr key={`${facility.facility}-${index}`}>
                            <Td>
                              <AppText>{facility.facility}</AppText>
                            </Td>
                            <Td>
                              <AppText>{facility.bookingCount}</AppText>
                            </Td>
                            <Td>
                              <AppText>{facility.bookingHourCount}</AppText>
                            </Td>
                          </Tr>
                        );
                      })}
                    <Tr>
                      <Td>
                        <AppText fontWeight={'bold'}>Total</AppText>
                      </Td>
                      <Td>
                        <AppText fontWeight={'bold'}>
                          {bookingCountByFacilityList &&
                            bookingCountByFacilityList.reduce(
                              (a, b) => a + b.bookingCount,
                              0
                            )}
                        </AppText>
                      </Td>
                      <Td>
                        <AppText fontWeight={'bold'}>
                          {bookingCountByFacilityList &&
                            bookingCountByFacilityList.reduce(
                              (a, b) => a + b.bookingHourCount,
                              0
                            )}
                        </AppText>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </AppCard>
          </Flex>
        </Flex>
      </Box>
    </BaseLayout>
  );
};
export default Dashboard;

// Card List
// TODAY BOOKINGS - API DONE

// ONE STAT CARD
// TENANT COUNT - API DONE

// ONE STAT CARD
// MOST BOOKINGS FOR TENANT FOR YEAR - API DONE

// ONE STAT CARD
// Booking Count Last Month - API DONE
// Booking Count Current Month - API DONE

// Booking Facilities for Current Year
// Table - Facility Name | Booking Count - API READY
