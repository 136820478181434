import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarEvent } from '../../models';
import { AppColors } from '../../theme';

type AppCalendarProps = {
  events: CalendarEvent[];
  children?: ReactNode;
  view?: string;
  handleSelect: (event: CalendarEvent) => any;
  handleSelectEdit: (event: CalendarEvent) => any;
};

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const AppCalendar: React.FC<AppCalendarProps> = ({
  events,
  children,
  view,
  handleSelect,
  handleSelectEdit,
  ...props
}) => {
  return (
    <Flex {...props}>
      <Flex
        w={'full'}
        h={'auto'}
        justify={'center'}
        align={'center'}
        direction={'column'}
        p={{base: 0, lg: 4}}
      >
        <Calendar
          selectable
          steps={7.5}
          timeslots={2}
          popup={true}
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={!view ? 'week' : view}
          events={events}
          style={{ height: 768, width: 'inherit' }}
          onSelectSlot={handleSelect}
          onSelectEvent={handleSelectEdit}
          eventPropGetter={(event) => {
            const backgroundColor = AppColors.primary;
            return { style: { backgroundColor } };
          }}
        />
      </Flex>
    </Flex>
  );
};
