import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FacilityBookingResult } from '@giflo/shared';
import { AppFetchBaseQuery } from './apiBase';
import {
  DateRangeRequest,
  OrganizationFacilityResult,
} from '../../models/report';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: AppFetchBaseQuery,
  endpoints: (build) => ({
    fetchReportFacilityBookings: build.query<
      FacilityBookingResult[],
      DateRangeRequest
    >({
      query: (body) => ({
        url: `/report/facilitybookings?from=${body.from}&to=${body.to}`,
        method: 'GET',
      }),
    }),
    fetchReportOrganizationFacilityBookings: build.query<
      OrganizationFacilityResult[],
      DateRangeRequest
    >({
      query: (body) => ({
        url: `/report/organizationfacilitybookings?from=${body.from}&to=${body.to}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyFetchReportFacilityBookingsQuery,
  useLazyFetchReportOrganizationFacilityBookingsQuery,
} = reportApi;
