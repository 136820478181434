import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Slider from 'react-slick';
import { Facility } from '../../models';

const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export type AppCarouselProps = {
  facility: Facility;
};

export const AppCarousel: React.FC<AppCarouselProps> = ({ facility }) => {
  const [slider, setSlider] = useState<Slider | null>();
  return (
    <Box
      position={'relative'}
      width={'full'}
      overflow={'hidden'}
    >
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
      />
      <link
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
      />
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {facility.images.map((image, index) => (
          <Box
            key={index}
            height={'6xl'}
            h={{base: '180px', lg: '250px'}}
            w={'auto'}
            position='relative'
            backgroundPosition='center'
            backgroundRepeat='no-repeat'
            backgroundSize='cover'
            backgroundImage={`url(${image.imageUrl})`}
          />
        ))}
      </Slider>
    </Box>
  );
};
