import React, { useState, useEffect } from 'react';
import { Input, FormControl, FlexProps, Flex, Box } from '@chakra-ui/react';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
  RefCallBack,
} from 'react-hook-form';
import { AppInputType } from '../app-input';
import { AppColors } from '../../theme';
import { AddIcon } from '@chakra-ui/icons';

export type AppImageUploaderProps<T extends FieldValues> = FlexProps & {
  name: Path<T>;
  error: FieldError | undefined;
  inputType: AppInputType;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const AppImageUploader = <T extends FieldValues>({
  onChange,
  name,
  error,
  inputType = 'file',
  ...props
}: AppImageUploaderProps<T>) => {
  return (
    <Flex {...props}>
      <Box
        w={'full'}
        h={'120px'}
        border={`1px solid ${AppColors.appBorder}`}
        boxShadow={'md'}
        bgColor={'white'}
        borderRadius={'md'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        pos={'relative'}
      >
        <Input
          w={'100%'}
          h={'100%'}
          type={inputType}
          name={name}
          multiple
          onChange={onChange}
          opacity={0}
          zIndex={5}
          cursor={'pointer'}
        />
        <AddIcon w={'25px'} h={'25px'} pos={'absolute'} color={AppColors.contentColor} />
        {error && <div style={{ color: 'red' }}>{error?.message}</div>}
      </Box>
    </Flex>
  );
};
