import { AppText } from '@giflo/shared';
import { Flex } from '@chakra-ui/react';

type OrganizationNameCellProps = {
  name: string;
};

const OrganizationNameCell: React.FC<OrganizationNameCellProps> = ({
  name,
}) => {
  return (
    <Flex>
      <AppText>{!name ? 'N/A' : `${name}`}</AppText>
    </Flex>
  );
};

export default OrganizationNameCell;
