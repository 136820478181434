import { Card, CardBody, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BaseLayout from '../../components/layouts/base-layout';
import { selectUser } from '../../store/slices/authentication.slice';
import { AppButton, AppColors, AppText } from '@giflo/shared';

const UserProfile: React.FC = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <BaseLayout>
      <Flex px={12} py={6} flex={1} direction="column">
        <Flex direction="row" justifyContent={'space-between'}>
          <AppText variant="h2">User Profile</AppText>
          <AppButton
            border={`1px solid ${AppColors.appBorder}`}
            borderRadius={'full'}
            mr="4"
            onClick={handleBackButton}
          >
            Back
          </AppButton>
        </Flex>
        <Flex w={'50%'} mt={10}>
          <Card flex={1}>
            <CardBody>
              <AppText variant="h3">
                Welcome {user?.firstName} {user?.lastName}
              </AppText>
              <Flex py={4} flexDir={'column'} gap={4}></Flex>
            </CardBody>
          </Card>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default UserProfile;
